* {
    box-sizing: border-box;
  }
  .hidden-menu{
      width: 120px;
      display: inline-block;
      transform: translate3d(150px,0,0);
      transition: transform .3s ease-in-out;
  }
  nav {
      position: fixed;
      z-index: 5000;
      top: 0;
      width: 100%;
      @include ru-regular;
      transition: all .3s ease-in-out;
      .grey-menu {
          
          width: 100%;
          padding: 4px 10px;
          background-color: $bg-grey;
          text-align: center;
          ul {
              display: inline-block;
              a {
                  text-transform: uppercase;
                  font-size: 0.625rem;
                  color: $font-grey; 
              }
              li {
                  line-height: 20px;
                  display: inline-block;
                  margin: 0 20px;
                  &.voice {
                      position: relative;
                      &:after {
                          position: absolute;
                          content: "";
                          width: 20px;
                          height: 20px;
                          top: 0;
                          left: -25px;
                          background-repeat: no-repeat;
                          background-position: center;
                      }
                      &.shop-voice {
                          &:after {
                              background-image: url('../assets/store_locator_icon.png')
                          }
                      }
                      &.cart-voice {
                          &:after {
                              background-image: url('../assets/cart.png')
                          }
                      }
                      &.rimb-voice {
                          &:after {
                              background-image: url('../assets/icon_rimb.png')
                          }
                      }
                      &.habit-voice {
                          &:after {
                              width: 20px;
                              height: 18px;
                              left: -25px;
                              background-image: url('../assets/15_days_icon.png')
                          }
                      }
                  }
              }
          }
      }
      
      &.one-line-menu {
          transform: translateY(0px);
          .hidden-menu{
              display: inline-block;
              transform: translate3d(-15px,0,0);
          }
          .main-menu-wrapper {
              padding: 0.625rem 0;
              transition: padding .3s ease-in-out;
              &:before {
                  transform: translate3d(-50%,-15px,0) scale(.6);
              }
              &:after {
                  content: "";
                  display: table;
                  clear: both;
              }
              .main-menu-left {
                  transform: translate3d(0,0,0);
              }
              .main-menu-right {
                  transform: translate3d(0,0,0);
              }
              .navigation {
                  .voice {
                      &.hidden {
                          width: auto;
                      }
                  }
                  a {
                      &.active {
                          color: $dark-blue;
                          li {
                              color: $dark-blue;
                          }
                      }
                      li {
                          padding: 0 5%;
                      }
                  }
              }
          }
          .logo-wrapper {
              transform: translate3d(-50%,-22px,0) scale(.6212);
          }
          .one-line-voice {
              opacity: 1;
              display: inline-block;
              margin: 0 6px;
              img {
                  width: 20px;
                  height: 20px;
              }
          }
      }
      .logo-wrapper {
          position: absolute;
          left: 50%;
          top: 0px;
          transform: translate3d(-50%,0,0) scale(1);
          transition: transform .3s ease-in-out;
          z-index: 5500;
          .logo-box {
              a {
                  display: block;
              }
              .logo {
                  width: 132px;
                  height: 132px;
              }
          }
      }
      .main-menu-wrapper {
          width: 100%;
          padding: 1.25rem 0;
          background-color: #FFF;
          line-height: 2;
          &:before {
              content: "";
              width: 330px;
              height: 75px;
              background-image: url('../assets/after_menu.png');
              background-size: contain;
              position: absolute;
              bottom: -73px;
              left: 50%;
              background-repeat: no-repeat;
              z-index: 0;
              transform: translate3d(-50%,0,0) scale(1);
              transition: transform .3s ease-in-out;
          }
          &:after {
              content: "";
              display: table;
              clear: both;
          }
          .main-menu-left {
              width: 47%;
              float: left;
              // margin-left: -3%;
              transform: translate3d(-50px,0,0);
              transition: transform .3s ease-in-out;
          }
          .main-menu-right {
              width: 47%;
              float: right;
              // margin-right: -3%;
              transform: translate3d(50px,0,0);
              transition: transform .3s ease-in-out;
          }
          .navigation {
              width: 100%;
              text-align: center;
              margin: 0;
              padding-left: 0;
              .voice {
                  &.hidden {
                      width: auto;
                  }
              }
              li {
                  font-size: 0.8125rem;
                  color: #5b5d64;
                  @include ru-regular;
                  display: inline-block;
                  letter-spacing: 1px;
                  width: 25%;
                  vertical-align: middle;
                  line-height: 2;
                  padding-left: 0;
                  margin-bottom: 0;
                  &:hover {
                      a {
                          color: $dark-blue;
                          &::after {
                            opacity: 1;
                            transform: scaleX(1);
                          }
                      }
                  }
              }
              a {
                position: relative;
                font-size: 0.9rem;
                color: #5b5d64;
                @include ru-regular;
                text-transform: uppercase;
                transition: color .2s ease-in-out;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -4px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: $dark-blue;
                    opacity: 0;
                    transform: scaleX(0);
                    transition: transform .2s ease-in-out, opacity .2s ease-in-out;
                }
                &.active {
                    color: $dark-blue;
                    &::after {
                        opacity: 1;
                        transform: scaleX(1);
                    }
                }
              }
          }
      }
  }
  
  @media screen and (max-width:1680px) {}
  
  @media screen and (max-width: 1440px) {
      html,body{
          font-size:16px;
      }
      nav.one-line-menu .main-menu-wrapper .navigation a li{
          padding:0 2%;
      }
      nav .logo-wrapper {
          img{
              max-width: 100%;
          }
      }
  }
  
  @media screen and (max-width:1366px) {}
  
  @media screen and (min-width: 992px) {
      html,
      body {
          font-size: 14px;
      }
      .desktop-menu {
          display: block;
      }
      .mobile {
          display: none;
      }
      .mobile-nav {
          display: none;
          width: 100%;
      }
  }
  
  @media screen and (max-width: 1200px) {
      //NAVIGATION START
      .desktop-menu {
          display: none;
      }
      .mobile-nav {
          background-color: #FFF;
          height: 70px;
          display: block;
          width: 100%;
          -webkit-box-shadow: 0 40px 100px -50px #545454;
          box-shadow: 0 40px 100px -50px #545454;
          .hidden {
              display: none;
          }
          .visible {
              display: block;
          }
          .navigation-to-show {
              width: 100%;
              position: fixed;
              top: 70px;
              left: 0;
              text-align: center;
              text-transform: uppercase;
              background-color: #FFF;
              height: 0;
              opacity: 0;
              overflow: auto;
              display: block;
              -webkit-transition: all 0.5s ease-in-out;
              -moz-transition: all 0.5s ease-in-out;
              -ms-transition: all 0.5s ease-in-out;
              -o-transition: all 0.5s ease-in-out;
              transition: all 0.5s ease-in-out;
              z-index: 5000;
              &.open-m {
                  opacity: 1;
                  height: 100vh;
                  -webkit-transition: all 0.5s ease-in-out;
                  -moz-transition: all 0.5s ease-in-out;
                  -ms-transition: all 0.5s ease-in-out;
                  -o-transition: all 0.5s ease-in-out;
                  transition: all 0.5s ease-in-out;
              }
              ul {
                padding-left: 0;
                list-style: none;
                  a {
                      &.active {
                          li {
                              color: $dark-blue;
                          }
                      }
                      li {
                          @include ru-light;
                          font-size: 2.8125rem;
                          color: #5b5d64;
                          text-transform: uppercase;
                          margin: 3.75rem auto;
                          &:hover {
                              color: $dark-blue;
                          }
                      }
                  }
              }
          }
      }
      .mobile-navigation {
          .menu-mobile-open {
              cursor: pointer;
          }
          ul {
            margin: 0;
              padding: 20px;
              top: 50%;
              transform: translateY(-50%);
              position: absolute;
              right: 0;
              a {
                  display: inline-block;
              }
              li {
                  display: inline-block;
                  margin: 0 10px;
                  text-align: center;
                  img {
                      width: 34px;
                      height: 34px;
                  }
              }
          }
      } //NAVIGATION END
      nav .logo-wrapper {
          width: 70px;
          height: 70px;
          padding: 5px;
          position: relative;
          left: unset;
          top: unset;
          text-align: left;
          transform: translate3d(0, 0, 0) scale(.7);
          img {
              max-width: 100%;
          }
      }
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
      .mobile-nav .navigation-to-show{
          top:90px;
      }
  }
  @media screen and (max-width: 600px) {
      .mobile-nav .navigation-to-show ul a li {
          font-size: 1.5rem;
          margin: 1.75rem auto;
      }
  }