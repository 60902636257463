@media screen and (min-width: 600px) {
    @media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi){
        html{
            font-size:60%;
        }
        nav .grey-menu ul a{
            font-size: .9rem;
        }
        nav .main-menu-wrapper .navigation a{
            font-size: 1.2rem;
        }
        .paragraph p,
        .paragraph ul li,
        .single-faq .toggle.question,
        .single-faq .inner.answer,
        .page-post-single .wrapper-content .category,
        .page-post-single .wrapper-content{
             font-size: 1.5rem;
         }
        .page-post-single .wrapper-content{
            line-height: 2rem;
        }
        .btn-primary,
        .wpcf7-submit{
            font-size: 1.5rem;
            // width:20rem;
        }
        .page-post-single .wrapper-content .info-post-author{
            font-size:1rem;
        }
    }
}
