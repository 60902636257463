$color :#b4dae8;
.slp_search_form .search_box {
    width: 70%;
    margin: 30px auto 30px;
}

.page-template-store-locator {
    .mainApp {
        height: auto;
    }
}

.store-locator-wrapper {
    padding: 30px 0 0 !important;
    background-color: rgba($color, 0.1);
    height: 100%;
    .intro-tab {
        text-align: center;
        p {
            font-size: 20px;
            text-align: center;
            margin: 22px auto;
            @include ru-regular;
            strong {
                @include ru-medium;
            }
        }
    }
}
.intro-tab-text {

    text-align: center;
    p {
        font-size: 20px;
        text-align: center;
        margin: 22px auto;
        color:#151f6d;
        @include ru-regular;
        strong {
            @include ru-medium;
        }
    }
}

.slp_search_form .search_box .search_item {
    flex: 1 !important;
}

.slp_search_form .search_box .search_item input[type="text"] {
    flex-basis: 70% !important;
    border: 0;
}

#addy_in_address {
    height: 38px;
    line-height: 38px;
}

#addy_in_radius {
    height: 38px;
    line-height: 38px;
    label {
        flex-basis: 136% !important;
        padding: 0 17px;
    }
}

#radius_in_submit {
    input {
        width: 90% !important;
        margin: 0 !important;
        cursor: pointer;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        pointer-events: all;
        text-align: center;
        @include ru-light;
        font-size: 1.15rem;
        display: block; // width: 225px;
        width: 14.0625rem;
        height: 38px; // line-height: 48px;
        color: white;
        background-color: $dark-blue;
        -webkit-box-shadow: 0 5px 15px 0px #cecece;
        box-shadow: 0 5px 15px 0px #cecece;
        cursor: pointer;
        margin: 25px 0;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        &:hover {
            background-color: $font-grey;
            color: #FFF;
        }
    }
}

.slp_search_form .search_box .search_item label {
    text-align: left !important;
    background-color: #FFF;
    margin-right: 0 !important;
    margin-left: 5px !important;
    flex-basis: 25% !important;
    padding-left: 18px !important;
}

.slp_search_form .search_box .search_item #addy_in_radius select,
.slp_search_form .search_box .search_item #addy_in_radius span.ui-widget {
    -webkit-appearance: none;
    background-color: #FFF;
    border: 0;
}

select {
    background-image: url('/assets/arrow_down_select.png');
    background-position: 87% center;
    background-image: 100%;
    background-repeat: no-repeat;
}

.slp_search_form .search_box .search_item #addy_in_radius label {
    padding: 0 0 0 17px !important;
}

.storelocatorlink {
    color: $font-grey;
    text-decoration: underline;
    display: block;
}

.slp_results_container {
    width: calc(100% - 140px);
    margin: 50px auto;
}

.slp_results_container .results_wrapper {
    border: 0 !important;
    border-radius: 0;
}

.results_entry {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    border-bottom: 2px solid #ecf0f2;
}

.results_row_left_column {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-start: 1;
    grid-column-end: 5;
    padding: 5px 0;
    .location_distance {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row: 1;
    }
    .location_name {
        grid-column-start: 4;
        grid-column-end: 12;
        grid-row: 1;
    }
}

.slp_results_container .results_wrapper {
    padding: 0 0 !important;
    margin: 0 !important;
}

.results_row_center_column {
    grid-column-start: 5;
    grid-column-end: 11;
    text-align: left;
    padding: 5px 0;
    .slp_result_address.slp_result_street {
        display: inline-block !important;
        width: 50%;
    }
    .slp_result_address.slp_result_citystatezip {
        display: inline-block !important;
        width: 28%;
    }
    .slp_result_address.slp_result_country {
        display: inline-block !important;
        width: 20%;
        margin-bottom: 0 !important;
    }
}

.results_row_right_column {
    grid-column-start: 11;
    grid-column-end: 13;
    padding: 5px 0;
}

.slp_results_container .results_wrapper:hover {
    border-radius: 0 !important;
    background-color: $bg-grey !important;
}

.filter-store {
    width: 100%;
    &:after {
        content: "";
        display: table;
        clear: both;
    }
    .single-filter {
        position: relative;
        display: block;
        float: left;
        // margin-top: 13vh;
        text-align: center;
        width: 50%;
        height: 400px;
        background-color:rgba($pink, .3);
        &:hover{
            background-color:rgba($pink, .6); 
            cursor: pointer;
        }        
        &.current{
            background-color:rgba($pink, .6);
            .wrapper-img{
                -webkit-filter: none; /* Safari 6.0 - 9.0 */
                filter: none;  
                &.handy{
                    &::before{
                        background: #151f6d;
                    }
                }
                &::before{
                    background: #151f6d;
                }
            }
        }
        .title{
            font-size:30px;
            position: absolute;
            bottom:20px;
            width: 100%;
            text-align: center;
        }
        .wrapper-img {
            top:30px;
            height: 300px;
            // width: 40vw;
            width: 80%;
            background-repeat: no-repeat;
            background-position: center 0;
            margin: 0 auto 0;
            background-size: contain;
            position: relative;
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
            &.handy{
                &:before{
                content: "400ML";
                display: block;
                width: 10vh;
                height: 10vh;
                background: grey;
                color: white;
                font-size: 1.5rem;
                font-family: 'Rubik';
                font-weight: bold;
                font-style: normal;
                border-radius: 100%;
                line-height: 10vh;
                position: absolute;
                top: 8vh;
                left: 28%;
                }
            }
            &:before {
                content: "1L";
                display: block;
                width: 10vh;
                height: 10vh;
                background: grey;
                color: white;
                font-size: 2.5rem;
                font-family: 'Rubik';
                font-weight: bold;
                font-style: normal;
                border-radius: 100%;
                line-height: 10vh;
                position: absolute;
                top: 8vh;
                left: 28%;
            }
        }
    }
}
.tab{
    display: none;
    position: relative;
    &.current {
        display: inherit;
    }
}

// animation on fadeIN
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    opacity: 0;
    /* make things invisible upon start */
    -webkit-animation: fadeIn ease-in 0.8s;
    /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
    -moz-animation: fadeIn ease-in 0.8s;
    animation: fadeIn ease-in 0.8s;
    -webkit-animation-fill-mode: forwards;
    /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 0.8s;
    -moz-animation-duration: 0.8s;
    animation-duration: 0.8s;
}

.fade-in.one {
    -webkit-animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
    animation-delay: 0.2s;
}
@media screen and (max-width:1440px){
    .filter-store .single-filter .wrapper-img:before{
        width: 10vh;
        height: 10vh;
        background: #151f6d;
        color: white;
        font-family: 'Rubik';
        font-weight: bold;
        font-style: normal;
        border-radius: 100%;
        line-height: 10vh;
        position: absolute;
        top: 10vh;
        left: 22%;
        font-size: 1.5rem;
    }
    .filter-store .single-filter .wrapper-img.handy:before{
        width: 10vh;
        height: 10vh;
        background: #151f6d;
        color: white;
        font-family: 'Rubik';
        font-weight: bold;
        font-style: normal;
        border-radius: 100%;
        line-height: 10vh;
        position: absolute;
        top: 10vh;
        left: 22%;
        font-size: 1.5rem;
    }
}
@media screen and (max-width:680px){
    .filter-store .single-filter{
        width: 100%;
        float: none;
    }
    .filter-store .single-filter .wrapper-img{
        width: 90%;
    }
    .slp_search_form .search_box .search_item{
        min-width: 100% !important;
    }
    .slp_search_form .search_box{
        width: 90%;
    }
    .intro-tab-text p{
        font-size: 1rem;
    }
    .slp_search_form .search_box .search_item label{
        font-size: 1rem;
    }
    .slp_search_form .search_box .search_item #addy_in_radius select, .slp_search_form .search_box .search_item #addy_in_radius span.ui-widget{
        font-size: 1rem;
    }
    #radius_in_submit input{
        width: 72% !important;
        font-size: 0.7rem;
        padding: 0;
        margin: 0 !important;
    }
    #addy_in_radius label{
        flex-basis: 106% !important;
    }
    .slp_search_form .search_box .search_item div{
        flex: 1 0 60%;
    }
    .slp_search_form .search_box .search_item #radius_in_submit{
        flex-direction: unset !important;
    }
    .slp_search_form .search_box .search_item div{
        flex: 1 0 50% !important;
    }
    .slp_search_form .search_box .search_item #addy_in_radius label{
        padding: 0 0 0 10px !important;
    }
    .slp_results_container{
        width: 90%;
        margin:25px auto;
    }
    .slp_results_container .results_wrapper{
        font-size: 0.777rem;
    }
    .slp_search_form .search_box .search_item #radius_in_submit{
        flex: 1 0 40% !important;
        margin-left: 27px;
        margin-right: 0;
    }
    #addy_in_radius {
        height: 38px;
        line-height: 38px;
        flex: 1 0 60% !important;
    }
    .filter-store .single-filter .wrapper-img.handy:before{
        font-size:1rem;
    }
    .page-template-store-locator{
        .intro-page-wrapper {
            text-align: center;
            padding: 7.5rem 0 4.0625rem;
        }
    }
    .intro-tab-text{
        padding:0 30px;
    }
    //GRID RESULTS
    .slp_results_container .results_wrapper .location_name{
        font-size:0.777rem !important;
    }
    .slp_results_container .results_wrapper span.slp_result_street, .slp_results_container .results_wrapper span.slp_result_street2, .slp_results_container .results_wrapper span.slp_result_citystatezip, .slp_results_container .results_wrapper span.slp_result_country, .slp_results_container .results_wrapper span.slp_result_hours{
        vertical-align: top !important;
    }
    .results_row_left_column {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column-start: 1;
        grid-column-end: 13;
        padding: 5px 0;
    }
    .results_row_center_column {
        grid-column-start: 1;
        grid-column-end: 13;
        text-align: left;
        padding: 5px 0;
    }
    .results_row_right_column {
        grid-column-start: 1;
        grid-column-end: 13;
        padding: 5px 0;
    }
    .results_row_left_column .location_distance{
        grid-column-start: 1;
        grid-column-end: 13;
    }
    .results_row_left_column .location_name{
        grid-column-start: 1;
        grid-column-end: 13;
        grid-row: 2;
    }
    .results_row_center_column .slp_result_address.slp_result_street{
        width: 100%;
    }
    .results_row_center_column .slp_result_address.slp_result_citystatezip{
        width: 100%;
    }
    .results_row_center_column .slp_result_address.slp_result_country{
        width: 100%;
    }
    .filter-store .single-filter .wrapper-img:before {
        width: 10vh;
        height: 10vh;
        background: #151f6d;
        color: white;
        font-family: 'Rubik';
        font-weight: bold;
        font-style: normal;
        border-radius: 100%;
        line-height: 10vh;
        position: absolute;
        top: 3vh;
        left: 12%;
        font-size: 1.5rem;
    }
    .filter-store .single-filter .wrapper-img.handy:before {
        top: 13vh;
        left: 13%;
        font-size:0.8rem;
    }
}

div#slp_tagline{
    display: none;
}