﻿/*
MAX WIDTH 600
*/

@media screen and (max-width: 600px) {
	.expert-home-block .wrapper-content .title {
		font-size: 4.3125rem;
		line-height: 4.3125rem;
		bottom: 48%;
	}
	.expert-home-block .wrapper-content .text-content {
		position: relative;
		bottom: 0;
		left: 0;
		width: 80%;
		margin: 0 auto;
		text-align: center;
		padding-top: 20%;
	}
	.wrapper-video-block .wrapper-content .text-conteiner .title {
		font-size: 4.3125rem;
	}
	.efw-claw .efw-claw-little-panel .efw-claw-wrapper .efw-claw-sx {
		width: 80%;
	}
	.efw-claw .efw-claw-little-panel .efw-claw-wrapper .efw-claw-dx {
		width: 20%;
	}
	.page-post-single .wrapper-content .info-post{
		float: none;
		width: calc(100% - 2.5rem);
		margin-left: 1.25rem;
	}
	.page-post-single .wrapper-content .content{
		float: none;
		width: calc(100% - 2.5rem);
		margin:2rem auto;
	}
	.page-post-single .wrapper-content .share-wrapper{
		float: none;
		width: calc(100% - 2.5rem);
		margin-left: 1.25rem;
	}
	.section-essentially-mobile{
		a{
			color:$font-grey;
		}
	}
	.section-essentially-mobile .wrapper-text-post{
		overflow: visible;
		height: auto;
	}
	.mobile-nav .navigation-to-show ul a li{
		font-size:1.25rem;
		margin:1.5rem auto;
		padding-left: 0;
	}
	.mobile-nav .navigation-to-show ul a.active li{
		@include ru-bold;
	}
	h1 {
		font-size: 2rem;
		line-height: 1.7777777778rem;
	}
	.page-template-essential_is .intro .wrapper-bottle {
		width: 20vw;
		text-align: center;
		margin: 0 auto 1rem;
	}
	.rituale-quotidiano .section-intro .spirale-wrapper {
		width: 100%;
	}
	.rituale-quotidiano .section-intro .wrapper-img {
		width: 100%;
		left: -10px;
	}
	.rituale-quotidiano .section-intro .wrapper-text.right {
		z-index: 100;
		width: 90%;
		left: 5%;
		top: 50%;		
	}
	.rituale-quotidiano .section-intro .wrapper-text .ritual-info{
		margin-left: 0;
	}
	.rituale-quotidiano .section-intro .wrapper-text .ritual-info img{
		max-width: 100%;
	}
	.subtitle {
		font-size: 1rem;
	}
	.testo-1 .paragraph ul, .testo-2 .paragraph ul, .testo-3 .paragraph ul{
		position: relative;
		li{
			padding-left: 1.6rem;
			&:before{
				position: absolute;
				left: 0;
			}
		}
	}
	section.cosa-dicono-video .title{
		font-size: 2em;
    	line-height: normal;
	}
	.page-template-rituale{
		.section-intro{
			.wrapper-img{
				display: none;
			}
		}	
	}
	
	.section-vitasnella-block .wrapper-content .text-container .title label{
		left: 0;
	}
}

/*
MAX WIDTH 320
*/
@media screen and (max-width: 320px) {
	
}

@media only screen and (max-width: 667px) {
	body,html{
		font-size:18px;
	}
	h1{
		font-size:2rem;
		line-height: 1.7777777778rem;
	}
	.subtitle{
		font-size:1rem;
	}
	.btn-primary{
		font-size:0.7777777778rem;
	}
	.mobile .section-intro .wrapper-text .scroll{
		font-size:0.6666666667rem;
		bottom: 2.1111111111rem;
		position: absolute;
		width: 100%;
	}
	// .mobile .section-intro .wrapper-spirale-video{
	// //	height:22.3333333333rem;
	// }
	.mobile .section-intro .wrapper-spirale-video .spirale{
		height: 15.3333333333rem;
	}
	.mobile .section-intro .bottiglia{
		height: 14.4444444444rem;
	}
	.mobile .section-intro .wrapper-text .subtitle{
		// margin-top: 1.3888888889rem;
		margin-top: 0.5rem;
	}
	.testo-1 .wrapper-title .title, .testo-2 .wrapper-title .title, .testo-3 .wrapper-title .title{
		font-size:1.2777777778rem;
		top:0.6666666667rem;
	}
	.testo-1 .wrapper-title::before, .testo-2 .wrapper-title::before, .testo-3 .wrapper-title::before{
		font-size:2.7777777778rem;
		line-height: 1.5;
	}
	.testo-1,
	.testo-2,
	.testo-3{
		.paragraph{
			ul{
				padding:0;
				li{
					font-size:0.7777777778rem;
					line-height: 1.5;
				}
			}
			p{
				font-size:0.7777777778rem;
				line-height: 1.5;
			}
		}
	}

	.wrapper-step-text .wrapper-title{
		height: auto;
		margin-bottom: 1.6666666667rem;
	}
	
	.mobile .section-intro .wrapper-spirale-video .spirale{
		height: 13.4444444444rem;
	}
	.mobile .section-intro .bottiglia{
		height: 14.4444444444rem;
	}
	.mobile .section-intro .wrapper-text{
		margin-top:20px;
	}

	//ESSENTIALY PAGE
	.essentially-wrapper{
		.intro-page-wrapper{
			padding: 6.1111111111rem 0 0;
		}
	}
	.essentially-wrapper .wrapper-container{
		width: calc(100% - 2.2222222222rem);
		margin:0 auto;
	}
	.essentially-wrapper .wrapper-filter{
		width: 100%;
		margin-top:2rem;
	}
	.essentially-wrapper .wrapper-filter .filter-voice{
		margin:1.1rem 0.5rem;
	}
	.essentially-wrapper{
		.single-post{
			width: 100%;
			margin:1.1rem 0;
			.wrapper-text-post {
				padding: 20px 0;
				margin-top: 30px;
				-webkit-transition: all 0.5s ease-in-out;
				-moz-transition: all 0.5s ease-in-out;
				-ms-transition: all 0.5s ease-in-out;
				-o-transition: all 0.5s ease-in-out;
				transition: all 0.5s ease-in-out;
				height: auto;
				p {
					font-size: 15px;
					margin-bottom: 20px;
				}
				.title-post {
					font-size: 1.0555555556rem;
					@include ru-light;
				}
				.wrapper-info-post {
					// margin-bottom: 40px;
					.wrapper-text {
						margin-bottom: 20px;
						font-size: 0.7777777778rem;
						line-height: 1.5;
					}
				}
				.link-post {
					color: $font-grey;
					font-size: 12px;
					@include ru-bold;
					margin: 20px 0;
					text-decoration: underline;
					opacity: 1;
					text-transform: uppercase;
				}
			}
		}
	}
	.mobile-wrapper-animation-content .mineral-list {
		width: 90%;
		margin-left: 0;
	}
	.mobile-wrapper-animation-content .wrapper-content{
		// background-size: 57%; 
		background-size: 37%; 
	}
	.section-boario-mobile{
		.wrapper-content{
			padding:2rem;
		}
	}
	.section-find-a-store-mobile .img{
		&:before{
			font-size: 2rem;
		}
		&.handy{
			&:before{
				font-size: 1rem;
			}
		}
	}
	.wrapper-home-statistic{
		padding:20px;
	}
	.mobile-wrapper-animation-content .title.size-s{
		width:100%;
	}
	.mobile .statistic-box-essenziale{
		// height:80vh;
		height: 100vh;
	}
	.statistic-box-essenziale{
		background: #a6d1e2;
		background: -moz-linear-gradient(top, #a6d1e2 0%, #71b8dd 44%, #429fd7 100%);
		background: -webkit-linear-gradient(top, #a6d1e2 0%, #71b8dd 44%, #429fd7 100%);
		background: linear-gradient(to bottom, #a6d1e2 0%, #71b8dd 44%, #429fd7 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6d1e2', endColorstr='#429fd7', GradientType=0);
		
		 .wrapper-content{
			.text-container .title{
				font-size: 2rem;
				strong{
					font-size: 2rem;
				}
			}
			.label-content{
				font-size: .8rem;
				padding:0;
			}
		}
	}
	.carousel-cell{
		flex-direction:column;
		padding:0;
		justify-content: center;
	}

	.mobile .boario-mobile .wrapper-claim h3{
		padding:20px;
	}
	// .section-easy-drink .column.right{
	// 	padding:50px 0;
	// }
	.statistic-box-essenziale .wrapper-content{
		width:100%;
	}
	.fe-for-page{
		.section-slide{
			height:90vh;
			
		}
		h1{
			font-size: 2rem;
		}
		.subtitle{
			    font-size: 1rem;
		}
	}
	.fe-for-page .block-slide-wrapper .single-block-slide.right .image-slide-block{
		background-position: 50%;
	}
	.fe-for-page .section-slide .block-slide-wrapper .single-block-slide.left .image-slide-block{
		background-position: 77%;
	}
	
	.rituale-quotidiano .quotes-section{
		padding:20px;
	}
	.rituale-quotidiano .quotes-section .statistic-essenziale-wrapper{
		.main-carousel{
			.carousel-cell{
				.quote-image{
					width:100px;
					height: 100px;
					margin-top:20px;
					margin-bottom:20px;
				}
				.quote{
					font-size: 1rem;
				}
			}
		}
	}
	.statistic-box-essenziale .source{
		font-size: .9rem;
		width: 100%;
    	padding: 10px 60px;
	}
	.rituale-quotidiano .quotes-section .statistic-essenziale-wrapper{
		.flickity-page-dots{
			// bottom:40px;
			bottom: 10px;
		}

		 .flickity-prev-next-button{
			top:80%;
		}
	}
	
	.single-faq .question{
		padding-right:2rem;
	}
	footer{
		.copy{
			font-size: .6rem;
		}
		.footer-menu{
			display: flex;
			flex-direction: column;
			.voice{
				a{
					line-height: 1rem;
					font-size: .8rem;
				}
			}
		}
	}
	.section.cosa-dicono-video .title{
		font-size: 2.85rem;
    	line-height: 3rem;
	}
	.page-template-rituale{
		.section-intro{
			.wrapper-img{
				display: none;
			}
		}
		
	}
	.page-post-single .header-img-wrapper .header-intro h1{
		padding:0;
		width: 90%;
		margin:0 auto;
	}
	.single-es_wall_post .back-to{
		width: calc(100% - 2.5rem);
    	margin-left: 1.25rem;
	}
	.wpcf7-submit {
		pointer-events: all;
		text-align: center;
		font-family: 'Rubik';
		font-weight: 300;
		font-style: normal;
		font-size: 1.15rem;
		display: block;
		width: 14.0625rem;
		height: 48px;
		line-height: 48px;
		color: white;
		background-color: #009cdd;
		-webkit-box-shadow: 0 5px 15px 0px #cecece;
		box-shadow: 0 5px 15px 0px #cecece;
		cursor: pointer;
		margin: 25px 0;
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-ms-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		-webkit-appearance: none ;
	}
}

@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
	.scroll{
		display: none;
	}
	.mobile .section-intro .wrapper-text{
		margin-top:30px;
	}
	.mobile .section-intro .wrapper-spirale-video .spirale{
		height: 13rem;
	}
	.btn-primary, .wpcf7-submit{
		max-width: 80%;
		display: block;
	}
	.wrapper-content .title{
		word-break: break-word;
	}
	.fe-for-page .wrapper-text-hide .paragraph p{
		font-size: 0.777rem;
    	padding: 0 0 0 2rem;
	}
	.fe-for-page .wrapper-block-text .title label{
		line-height: 1;
	}
	.fe-for-page .wrapper-block-text .title label{
		bottom:0;
	}
	.mobile .section-intro .bottiglia{
		height: 13rem;
		top:90px;
	}
	.mobile .section-intro .wrapper-spirale-video .spirale{
		height: 12rem;
		top:100px;
	}
	.section-find-a-store-mobile .img:before{
		font-size:1rem;
	}
	 .wpcf7-submit {
		pointer-events: all;
		text-align: center;
		font-family: 'Rubik';
		font-weight: 300;
		font-style: normal;
		font-size: 1.15rem;
		display: block;
		width: 14.0625rem;
		height: 48px;
		line-height: 48px;
		color: white;
		background-color: #009cdd;
		-webkit-box-shadow: 0 5px 15px 0px #cecece;
		box-shadow: 0 5px 15px 0px #cecece;
		cursor: pointer;
		margin: 25px 0;
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-ms-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		-webkit-appearance: none ;
	}
	.flickity-prev-next-button {
		width: 24px;
		height: 24px;
		&.previous {
			left: 0;
		}
		&.next {
			right: 0;
		}
	}
}