.wpDataTablesWrapper table.wpDataTable>thead>tr>th{
    border:0;
    padding-bottom:30px  !important;
    font-size:1.2rem;
    color:$font-grey !important;
}
.wpDataTablesWrapper tfoot{
    display:none;
}
.dt-buttons{
    display: none;
}
.wpTables h2{
    display:none;
}
.wpDataTablesWrapper table.wpDataTable > tbody > tr > td, 
.wpDataTablesWrapper table.wpDataTable > tfoot > tr > td{
    border-left:0px !important;
    border-right:0px !important;
    padding: 5px;
}
.dataTables_info{
   display:none;
}

.wpDataTablesWrapper .dataTables_paginate{
    margin: auto !important;
    float: none !important;
    text-align: center !important;
}

.wdt-filter-control::placeholder{
    @include ru-regular;
}

.dataTables_length,
.dataTables_filter{
    display:none;
}

.wpDataTableFilterBox{
    display: flex;
    justify-content: center;
    text-align: left;
}


@media screen and (max-width: 768px) {
    .wpDataTablesWrapper table.wpDataTable{
        table-layout: fixed !important;
    }
    .wpDataTablesWrapper table.wpDataTable tr td{
        font-size:1rem !important;
    }
}

@media screen and (max-width: 400px) {
    .wpDataTableFilterBox{
        flex-direction: column !important;
    }
    .wpDataTablesFilter .wpDataTableFilterSection:not(#wdt-clear-filters-button-block):not(#wdt-cf-search-filters-button-block){
        float: none !important;
        margin-bottom: 0!important;
        margin-right: 0!important;
        width: 100%!important;
        min-height: auto!important;
        padding:0 !important;
    }
}