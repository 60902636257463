﻿/*
MAX WIDTH 992
*/

@media screen and (min-width: 992px) {
	
	.mobile {
		display: none;
	}
	
}

/// maxwidth: 991px or tablet landscape
@media screen and (max-width: 991px),
screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
	.efw-claw .efw-claw-panel {
		width: 90%;
	} 
	html,
	body {
		font-size: 18px;
		overflow: unset !important;
	}
	.desktop {
		display: none;
	}
	.desktop-menu {
		display: none;
	}
	.parte-scroll-misto {
		position: relative !important;
		display: block !important;
	} 
	.mobile {
		display: block;
		position: relative;
		width: 100%; // height: 100%;
		.section-intro {
			width: 100%;
			padding: 0;
			margin: 0;
			height: 100vh;
			position: relative;
			.bottiglia {
				width: 30vw;
				height: 70%;
				text-align: center;
				background-image: url("/assets/bottiglia_ita/bottiglia_1.png");
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				z-index: 200;
				left: 35vw;
				top: 100px;
			}
			.wrapper-spirale-video {
				width: 100%;
				height: 57%;
				background-color: #FFF;
				position: relative;
				margin-bottom: 0;
				.spirale {
					position: absolute;
					z-index: 20;
					width: 80%;
					height: 65%;
					top: 110px;
					left: 10%;
					background-image: url("/assets/solo_spirale.png");
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
				}
			}
			.wrapper-text {
				text-align: center;
				.subtitle {
					margin-left: 0;
					margin-top: 0;
				}
				.btn-primary {
					margin: 15px auto;
				}
				.scroll {
					position: relative;
					@include ru-light;
					font-size: 1.5rem;
					text-transform: uppercase;
					color: #b0b7bc;
					&:after {
						background-image: url('/assets/mobile_scroll_arrow.png');
						background-size: contain;
						background-position: center;
						width: 19px;
						height: 30px;
						position: absolute;
						content: "";
						bottom: -32px;
						left: calc(50% - 7.5px);
					}
				}
			}
		}
	} //HOME WRAPPER EXPERT
	.expert-home-block {
		height: auto;
		width: 100%;
		overflow: auto;
		padding: 50px 0;
		.wrapper-content {
			width: 100%;
			.wrapper-img {
				position: relative;
				width: 80%;
				margin: 0 auto;
			}
			.title {
				text-align: center;
				left: 0;
				top: unset;
				bottom: 25%;
				margin: 10% 0;
				width: 100%;
			}
			.text-content {
				position: relative;
				bottom: 0;
				left: 0;
				width: 80%;
				margin: 0 auto;
				text-align: center;
				padding-top: 10%;
				.btn-primary {
					margin: 25px auto;
				}
			}
		}
	} //HOME WRAPPER EXPERT END
	//HOME STATISTI
	.wrapper-home-statistic {
		pointer-events: none;
		.wrapper-content {
			pointer-events: none;
			background-color: #fbfbfb;
			background-image: unset !important;
			min-height: 46vh;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.wrapper-text {
				opacity: 1;
				width: calc(100% - 1.5rem);
				margin: 0 auto;
				left: 0;
				text-align: center;
				position: static;
				display:flex;
				flex-direction: column;
				justify-content: space-around;
				.box-text {
					max-width: unset;
					position: static;
				.title {
						p {
							font-size: 1.2777777778rem;
							line-height: 1.5;
						}
					}
				}
				.btn-primary {
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 0;
				}
			}
		}
	} //HOME STATISTI END
	.wrapper-video-block {
		padding: 0;
		.wrapper-content {
			padding: 0 0 50px;
			width: 100%;
			height: auto;
			.text-conteiner {
				width: 80%;
				margin: 0 auto;
				position: relative;
				.btn-primary {
					margin: 25px auto;
				}
				.title {
					text-align: center;
					font-size: 6.3125rem;
					top: -30px;
					label {
						bottom: -100%;
						left: 0;
						text-align: center;
						width: 100%;
					}
				}
				.paragraph {
					text-align: center;
					padding: 0;
					margin: 0;
				}
			}
		}
		.wrapper-img {
			position: relative;
			width: 100%;
			img {
				width: 100%;
			}
		}
	}
	.modal .wrapper-iframe {
		width: 90%;
		left: 5%;
	}
	.modal .close-modal {
		left: calc(95% - 18px);
	}
	.wrapper-content {
		.text-content {
			.text {
				margin: 3rem auto;
			}
		}
	} // .wrapper-content p {
	// 	font-size: 1.5rem;
	// 	line-height: normal;
	// } //BOARIO SECTION
	.section-boario-mobile {
		.wrapper-content {
			padding:5rem;
			text-align: center;
			img {
				width: 70%;
			}
			.btn-primary {
				margin: 25px auto 0;
			}
			.paragraph {
				padding: 0;
				p {
					font-size: 0.7777777778rem;
					line-height: 1.5;
					text-align: center;
				}
			}
		}
		.wrapper-claim {
			height: 17.6666666667rem;
			position: relative;
			text-align: center;
			background-size: 200%;
			background-position: -184px;
		
			h3 {
				width: 100%;
				font-size: 3rem;
				color: #FFF;
				@include ru-light;
				padding: 0 3.6666666667rem;
				text-shadow: 0px 0px 5px rgba(0, 0, 0, .5);
			}
		}
	} //FIND A STORE
	.section-find-a-store-mobile {
		text-align: center;
		padding: 3rem 0;
		background-color: rgba($pink, 0.3);
		.btn-primary {
			margin: 25px auto 0;
		}
		.title {
			@include ru-light;
			font-size: 30px;
		}
		.subtitle {
			font-size: 15px;
			@include ru-light;
			margin-top: 20px;
			margin-left: 0;
		}
		.img {
			width: 60%;
			margin: 0 auto;
			position: relative;
			&:before{
				content: "1L";
				display: block;
				// width: 15vh;
				// height: 15vh;
				background: #151f6d;
				color: white;
				// font-size: 5rem;
				font-family: 'Rubik';
				font-weight: bold;
				font-style: normal;
				border-radius: 100%;
				// line-height: 15vh;
				position: absolute;
				// top: 20vh;
				// left: 10vh;
				font-size: 2rem;
				top: 15%;
				left: 5%;
				padding: 3vw; 
			}
			&.handy{
				&:before{
					content: "400 ML";
					width: 15vh;
					height: 15vh;
					font-size: 2rem;
					line-height: 15vh;
					top: 20vh;
					left: 10vh;
					font-size: 1rem;
				}
			}
		}
	}
	.product-slide,
	.animation-slide {
		.owl-carousel .owl-stage{
			padding:100px 0 ;
		}
		.owl-nav {
			.owl-next {
				width: 40px !important;
				height: 40px !important;
				top: calc(50% - 20px) !important;
				background-image: url('/assets/arrow_grey_next.png') !important;
				background-size: 15px !important;
				background-repeat: no-repeat !important;
				right: 0 !important;
				border-radius: 50% !important;
				background-color: #fff !important;
				padding: 0 !important;
				opacity: 1 !important;
				background-position: 15px center;
			}
			.owl-prev {
				width: 40px !important;
				height: 40px !important;
				top: calc(50% - 15px) !important;
				background-image: url('/assets/arrow_grey_prev.png') !important;
				background-size: 15px !important;
				background-repeat: no-repeat !important;
				border-radius: 50% !important;
				background-color: #fff !important;
				padding: 0 !important;
				opacity: 1 !important;
				background-position: 10px center;
				left: 0 !important;
			}
		}
		.paragraph {
			padding: 0;
		}
	}
	.slider-animation-mobile {
		.img-bottiglia {
			padding-top:100px;
			img{
				width: auto;
    			height: 40vh;
    			margin: auto;
			}
		}
		.owl-theme .owl-nav {
			margin-top: 0;
		}
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f0f9ff+0,f3f9fb+47,e8f4f8+100 */
		background: #f0f9ff;
		/* Old browsers */
		background: -moz-linear-gradient(top,
		#f0f9ff 0%,
		#f3f9fb 47%,
		#e8f4f8 100%);
		/* FF3.6-15 */
		background: -webkit-linear-gradient(top,
		#f0f9ff 0%,
		#f3f9fb 47%,
		#e8f4f8 100%);
		/* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,
		#f0f9ff 0%,
		#f3f9fb 47%,
		#e8f4f8 100%);
		/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0f9ff',
		endColorstr='#e8f4f8',
		GradientType=0);
		/* IE6-9 */
		.item {
			// width: 60%;
			width: calc(100% - 5rem);
			margin: 0 auto;
			text-align: center;
		}
		.paragraph {
			p {
				text-align: center;
				font-size: 2rem;
				line-height: 1;
			}
		}
		.btn-primary {
			margin: 25px auto;
		}
	}
	.testo-1,
	.testo-2,
	.testo-3 {
		.paragraph {
			p,li {
				font-size: 1rem;
				line-height: 1.5rem;
			}
			
		}
		.wrapper-title {
			&::before {
				width: 100% !important;
				text-align: center;
				top: -10px;
			}
			.title {
				position: relative;
				left: 0;
			}
		}
	}
	.wrapper-home-statistic .wrapper-content .wrapper-text .percentage-number {
		font-size: 5.2777777778rem;
	}
	.wrapper-video-block .container {
		width: 100%;
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-orient: vertical;
		-moz-box-orient: vertical;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-moz-box-align: start;
		-ms-flex-align: start;
		-webkit-align-items: flex-start;
		align-items: flex-start;
	}
	.wrapper-video-block .container .wrapper-image-video {
		width: 100%;
		margin-left: 0;
		float: none;
	}
	.wrapper-video-block .container .wrapper-content {
		width: 100%;
		padding: 1.1111111111rem;
		margin-right: 0;
		float: none;
		-webkit-box-ordinal-group: 2;
		-moz-box-ordinal-group: 2;
		-ms-flex-order: 1;
		-webkit-order: 1;
		order: 1;
		.paragraph {
			padding: 0;
			p {
				font-size: 0.7777777778rem;
				line-height: 1.5rem;
				text-align: center;
				margin-top: 0;
			}
		}
	}
	.wrapper-video-block .container .wrapper-content .title {
		// font-size: 2.7777777778rem;
		font-size: 11vw;
		text-align: center;
		label {
			font-size: 1.2777777778rem;
			// width: 100%;
			left: 0;
			bottom: 1.6666666667rem;
			text-align: center;
			margin: -10px 0 20px 0;
		}
	}
	.section-essentially-mobile {
		background-color: rgba(#dce5ea, 0.1);
		padding-top: 0.5rem;
		.title {
			text-align: center;
			font-size: 1.5rem;
			margin-top: 2rem;
			margin-bottom: 0;
			@include ru-light;
		}
		.owl-nav {
			.owl-next {
				width: 40px !important;
				height: 40px !important;
				top: calc(50% - 20px) !important;
				background-image: url('/assets/arrow_grey_next.png') !important;
				background-size: 15px !important;
				background-repeat: no-repeat !important;
				right: 0 !important;
				border-radius: 50% !important;
				background-color: #dce5ea !important;
				padding: 0 !important;
				opacity: 1 !important;
			}
			.owl-prev {
				width: 40px !important;
				height: 40px !important;
				top: calc(50% - 20px) !important;
				left: 0 !important;
				background-image: url('/assets/arrow_grey_prev.png') !important;
				background-size: 15px !important;
				background-repeat: no-repeat !important;
				right: 0 !important;
				border-radius: 50% !important;
				background-color: #dce5ea !important;
				padding: 0 !important;
				opacity: 1 !important;
			}
		}
		.single-post {
			position: relative;
			width: calc(100% - 2.8888888889rem);
			margin: 2rem auto;
			&:hover {
				.wrapper-text-post {
					-webkit-transform: translateY(-40px);
					-moz-transform: translateY(-40px);
					-ms-transform: translateY(-40px);
					-o-transform: translateY(-40px);
					transform: translateY(-40px);
					.link-post {
						opacity: 1;
					}
				}
			}
		}
		.type-post {
			text-transform: uppercase;
			font-size: 15px;
			@include ru-light;
			display: inline;
		}
		.reading-time {
			font-size: 15px;
			@include ru-light;
			text-align: right;
			// float: right;
			display: inline;
		}
		.social-icon {
			border-radius: 50%;
			background-color: #dce5ea;
			text-align: center;
			position: absolute;
			bottom: 20px;
			left: 20px;
			width: 40px;
			height: 40px;
			line-height: 55px;
			img {
				height: 25px;
				width: auto;
				margin-left: auto;
				margin-right: auto;
				margin-top: 8px;
			}
		}
		img {
			width: 100%;
			max-width: 100%;
		}
		.box-post {
			background-color: #dce5ea;
			padding: 20px 20px 0;
			margin-top: -5px;
		}
		.wrapper-text-post {
			padding: 20px 0;
			margin-top: 30px;
			-webkit-transition: all 0.5s ease-in-out;
			-moz-transition: all 0.5s ease-in-out;
			-ms-transition: all 0.5s ease-in-out;
			-o-transition: all 0.5s ease-in-out;
			transition: all 0.5s ease-in-out;
			height: 100px;
			overflow: auto;
			p {
				font-size: 15px;
				margin-bottom: 20px;
			}
			.title-post {
				font-size: 1.0555555556rem;
				@include ru-light;
			}
			.wrapper-info-post {
				// margin-bottom: 40px;
				.wrapper-text {
					margin-bottom: 20px;
					font-size: 0.7777777778rem;
					line-height: 1.5;
				}
			}
			.link-post {
				color: $font-grey;
				font-size: 12px;
				@include ru-bold;
				margin: 20px 0;
				text-decoration: underline;
				opacity: 1;
				text-transform: uppercase;
			}
		}
		.info-slide-number {
			text-align: center;
			margin: -30px auto 30px;
			color: #5b5d64;
			font-size: 0.8333333333rem;
		}
	}
	.wrapper-video-block .container .btn-primary {
		margin: 2.1rem auto;
	}
	.column {
		width: 100%;
		float: none;
		margin: 0;
	}
	.column-3 {
		width: 100%;
	}
	.about-us-page-details .single-row-block {
		&.video-block {
			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-box-orient: vertical;
			-moz-box-orient: vertical;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
			-webkit-box-align: start;
			-moz-box-align: start;
			-ms-flex-align: start;
			-webkit-align-items: flex-start;
			align-items: flex-start;
			.column {
				&:nth-child(1) {
					-webkit-box-ordinal-group: 2;
					-moz-box-ordinal-group: 2;
					-ms-flex-order: 1;
					-webkit-order: 1;
					order: 1;
				}
			}
			.text-wrapper {
				padding-left: 0;
				padding-bottom: 1rem;
			}
			.title {
				// font-size: 11vw;
				font-size: 2.7777777778rem;
			}
		}
		&.left {
			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-box-orient: vertical;
			-moz-box-orient: vertical;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
			-webkit-box-align: start;
			-moz-box-align: start;
			-ms-flex-align: start;
			-webkit-align-items: flex-start;
			align-items: flex-start;
			.column {
				&:nth-child(1) {
					-webkit-box-ordinal-group: 2;
					-moz-box-ordinal-group: 2;
					-ms-flex-order: 1;
					-webkit-order: 1;
					order: 1;
				}
			}
		}
	}
	.fe-for-page {
		.row-single-block {
			&.right {
				display: -webkit-box;
				display: -moz-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				-webkit-box-orient: vertical;
				-moz-box-orient: vertical;
				-webkit-flex-direction: column;
				-ms-flex-direction: column;
				flex-direction: column;
				-webkit-box-align: start;
				-moz-box-align: start;
				-ms-flex-align: start;
				-webkit-align-items: flex-start;
				align-items: flex-start;
				margin-top: 2rem;
				.column {
					&:nth-child(2) {
						-webkit-box-ordinal-group: 2;
						-moz-box-ordinal-group: 2;
						-ms-flex-order: 1;
						-webkit-order: 1;
						order: 1;
					}
				}
			}
			&.left {
				display: -webkit-box;
				display: -moz-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				-webkit-box-orient: vertical;
				-moz-box-orient: vertical;
				-webkit-flex-direction: column;
				-ms-flex-direction: column;
				flex-direction: column;
				-webkit-box-align: start;
				-moz-box-align: start;
				-ms-flex-align: start;
				-webkit-align-items: flex-start;
				align-items: flex-start;
				.column {
					&:nth-child(1) {
						-webkit-box-ordinal-group: 2;
						-moz-box-ordinal-group: 2;
						-ms-flex-order: 1;
						-webkit-order: 1;
						order: 1;
					}
				}
			}
		}
	}
	.fe-for-page .block-slide-wrapper .single-block-slide .wrapper-arrow-slide {
		bottom: -5.25rem;
	}
	.fe-for-page .row-single-block .column:nth-child(1) {
		margin: 0;
	}
	.fe-for-page .row-single-block .column:nth-child(2) {
		margin: 0;
		min-height: 0 !important;
	} //ABOUT US
	.about-us-page .wrapper-text .wrapper-content {
		padding: 0;
		margin: 50px 0;
		&.v-center {
			position: relative;
			top: unset;
			transform: translateY(0);
		}
	}
	.sustainability-section .wrapper-content {
		width: calc(100% - 8.75rem);
		margin: 1.25rem auto;
		display: block;
	}
	.sustainability-section .wrapper-image-video {
		width: calc(100% - 8.75rem);
		margin: 1.25rem auto;
		display: block;
	}
	.section-food-wrapper .image-wrapper {
		width: calc(100% - 2.5rem);
		margin: 1.25rem auto;
		display: block;
		padding: 0;
	}
	.section-food-wrapper .text-container .title {
		font-size: 2.7777777778rem;
		label {
			font-size: 1.2222222222rem;
		}
	}
	.section-food-wrapper {
		padding: 0;
		margin: 0;
	}
	.section-food-wrapper .text-container {
		width: 100%;
		margin: 1.25rem auto;
		display: block;
		padding: 0 1.25rem;
		.paragraph {
			padding: 0;
			p {
				font-size: 0.7777777778rem;
				text-align: left;
			}
		}
	} //ASK THE EXPERT
	.ask-page .section-form {
		margin: 1rem 0 0;
	}
	.about-us-page-details .single-row-block:nth-child(1) {
		margin-top: 0;
	}
	.about-us-page-details .single-row-block .image-wrapper {
		height: 12.2222222222rem;
	}
	.about-us-page-details .single-row-block .column {
		min-height: unset;
	}
	.about-us-page-details .title {
		font-size: 2.7777777778rem;
		line-height: 1;
		margin: 1rem 0;
		label {
			font-size: 1.2222222222rem;
		}
	}
	.about-us-page-details .section-block-text .text-wrapper .paragraph {
		padding: 0;
		p {
			font-size: 0.7777777778rem;
			text-align: left;
		}
	}
	.about-us-page-details .section-block-text .text-wrapper.v-align {
		margin-top: 0;
	}
	.container-box .single-box .label {
		font-size: 2rem;
	}
	.ask-page .section-expert-video .wrapper-content .wrapper-img {
		width: 100%;
		position: relative;
	}
	.ask-page .section-expert-video .wrapper-content .title {
		left: 0;
		position: relative;
		width: 100%;
	}
	.ask-page .section-expert-video .wrapper-content .text-content {
		left: 0;
		position: relative;
	}
	.ask-page .section-expert-video .wrapper-content {
		height: auto;
	}
	.ask-page .section-expert-video {
		width: calc(100% - 8.75rem);
		margin: 1.25rem auto;
	}
	.section-video .single-video {
		width: 100%;
		float: none;
		margin: 3.25rem auto;
	} //FE for page
	.fe-for-page .row-single-block.left .wrapper-content {
		width: 100%;
	}
	.fe-for-page {
		.v-center {
			position: relative;
			top: unset;
			transform: translateY(0);
		}
	}
	.fe-for-page .row-single-block.right .wrapper-content {
		width: 100%;
		margin-top: 2rem;
	}
	.fe-for-page .wrapper-block-text {
		margin: 1.25rem auto;
	}
	.fe-for-page .row-single-block {
		margin: 1.25rem auto;
	}
	.fe-for-page .row-single-block.left .wrapper-content {
		margin: 1.25rem auto;
	} //FOOTER
	footer ul {
		width: 100%;
		text-align: center;
	}
	.wrapper-menu-service {
		display: block;
		width: 80%;
		margin: 0 auto;
	}
	footer .footer-menu {
		width: 100%;
		margin: 10px auto;
		column-count: 2;
		text-align: center;
		-webkit-column-rule-style: solid;
		/* Chrome, Safari, Opera */
		-moz-column-rule-style: solid;
		/* Firefox */
		column-rule-style: solid;
		-webkit-column-rule-color: $font-grey;
		/* Chrome, Safari, Opera */
		-moz-column-rule-color: $font-grey;
		/* Firefox */
		column-rule-color: $font-grey;
		-webkit-column-rule-width: 2px;
		/* Chrome, Safari, Opera */
		-moz-column-rule-width: 2px;
		/* Firefox */
		column-rule-width: 2px;
		.voice {
			width: 100%;
			margin: 12px auto;
		}
	}
	footer .copy {
		float: none;
	} //ABOUST US
	.about-us-page .header-img-wrapper .header-intro.v-center {
		margin-left: 0;
		top: calc(40% + 2.5rem);
		width: 80%;
		left: 10%;
		.subtitle {
			margin-left: 0;
			margin-top: 10px;
		}
	}
	.about-us-page .section-slider-about-us {
		width: 100%;
		padding: 2.5rem 0;
	}
	.about-us-page .section-slider-about-us .wrapper-slider {
		width: 100%;
		height: 19.4444444444rem;
	}
	.about-us-page .section-slider-about-us .wrapper-text .wrapper-content .title {
		font-size: 2.7777777778rem;
		label {
			font-size: 1.2222222222rem;
		}
	}
	.about-us-page .section-slider-about-us .wrapper-text .wrapper-content {
		padding: 0 1.1111111111rem;
		.paragraph {
			padding: 0;
			p {
				font-size: 0.7777777778rem;
				text-align: left;
			}
		}
	}
	.sustainability-section {
		padding: 0 0 1rem;
		.paragraph {
			padding: 0;
			p {
				font-size: 0.7777777778rem;
				text-align: left;
			}
		}
	}
	.sustainability-section .container .wrapper-content .title {
		// font-size: 2.7777777778rem;
		font-size: 11vw;
		line-height: 1;
		label {
			font-size: 1.2222222222rem;
			bottom: 0;
		}
	}
	.about-us-page .section-slider-about-us .wrapper-text {
		margin-left: 0;
	}
	.sustainability-section .container .wrapper-content {
		width: 100%;
		padding: 0 1.1111111111rem;
		margin-right: 0;
		float: none;
		-webkit-box-ordinal-group: 2;
		-moz-box-ordinal-group: 2;
		-ms-flex-order: 1;
		-webkit-order: 1;
		order: 1;
	}
	.sustainability-section .container {
		width: 100%;
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-orient: vertical;
		-moz-box-orient: vertical;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-moz-box-align: start;
		-ms-flex-align: start;
		-webkit-align-items: flex-start;
		align-items: flex-start;
	}
	.sustainability-section .container .wrapper-image-video {
		width: 100%;
		float: none;
		position: relative;
		margin: 0;
	}
	.about-us-page-details .section-block-text {
		width: calc(100% - 2.5rem);
	}
	.about-us-page-details .single-row-block .column.video-img {
		width: 100%;
		margin-bottom: 0;
	}
	.about-us-page-details .single-row-block .column.video-img {
		padding-left: 0;
	}
	.about-us-page-details .single-row-block .column.video-text {
		width: 100%;
	}
	.about-us-page-details .single-row-block.right .text-wrapper {
		padding-left: 0;
		margin: 1.25rem auto;
	}
	.about-us-page-details .section-block-text .text-wrapper .paragraph p {
		font-size: 0.7777777778rem;
	}
	.about-us-page-details .title {
		word-wrap: break-word;
	}
	.about-us-page-details .section-details-box-link .column {
		width: 100%;
		min-height: unset;
	}
	.back-to {
		margin: 0 1.25rem;
	}
	.back-to.s-size {
		font-size: 1rem;
		margin-left: 0;
		margin-top: 2rem;
	}
	.about-us-page-details .single-row-block .column {
		width: 100%;
	}
	.about-us-page-details .single-row-block {
		.text-wrapper {
			.v-center {
				top: 0;
				transform: translateY(0);
				position: relative;
			}
		}
	}
	.about-us-page-details .header-img-wrapper .header-intro {
		margin-left: 2.5rem;
	}
	.about-us-page-details .single-row-block {
		margin: 1.25rem auto;
	}
	.intro-page-wrapper .subtitle {
		padding: 0 1.50rem;

	}
	.fe-for-page {
		h1 {
			font-size: 3.65rem;
		}
		.intro-page-wrapper {
			padding: 5.5555555556rem 0 0;
			
			.subtitle {
				font-size: 1.2rem;
				margin: 1rem 0;
			}
		}
		.wrapper-text-hide {
			.wrapper-text {
				width: 95%;
				left: 0;
			}
			.paragraph {
				font-size: 0.56rem; // padding:0 1.1rem;
				p {
					font-size: 1rem;
					line-height: 1.5;
					text-align: left;
					padding: 0 1.5rem 0 1.1rem;
				}
				ul {
					padding-right: 1.5rem;
				}
			}
		}
	}
	.fe-for-page .block-slide-wrapper .single-block-slide .wrapper-text-hide .title {
		font-size: 3rem;
		padding: 1.1rem;
	}

	.fe-for-page .block-slide-wrapper .single-block-slide.left .wrapper-text-hide {
		width: 87%;
		left: 0;
	}
	.fe-for-page .block-slide-wrapper .single-block-slide.right .wrapper-text-hide {
		width: 87%;
		right: 0;
	}
	.fe-for-page .wrapper-block-text .title {
		font-size: 2.7777777778rem;
	}
	.fe-for-page .wrapper-block-text .title label {
		font-size: 1.2222222222rem; // line-height: 1;
	}
	.fe-for-page .row-single-block .column .paragraph {
		padding: 0;
		p {
			font-size: 1rem;
			text-align: left;
		}
	}
	.fe-for-page .wrapper-block-text {
		width: calc(100% - 2.5rem);
	}
	.fe-for-page .row-single-block .wrapper-image {
		height: 35vh;
	}
	.fe-for-page .section-slide .bottiglia-wrapper {
		display: none;
	}
	.ask-page .section-form .wrapper .subtitle {
		margin-left: 0;
	}
	.ask-page .intro-page-wrapper {
		padding: 6rem 0 1rem;
	}
	.ask-page .intro-page-wrapper .subtitle {
		padding: 0 2.5rem;
		margin-top: 1rem;
	}
	.single-faq {
		margin: 5px 0;
	}
	.single-faq .question {
		font-size: 0.8333333333rem;
		padding: 0.8333333333rem;
		padding-right: 2.3rem;
	}
	.single-faq .question:after {
		right: 0.5555555556rem;
		top: calc(50% - 10px);
	}
	.ask-page .section-form .wrapper {
		width: calc(100% - 2.2222222222rem);
		margin: 0 auto;
		padding: 1.9444444444rem 0 1rem;
	}
	div.wpcf7 {
		margin: 1.9444444444rem 0 0 0 !important;
		padding: 0;
		text-align: left;
	}
	.ask-page .section-form .form-wrapper {
		margin: 1rem 0 0 0;
	}
	.ask-page .section-form .wrapper .subtitle {
		font-size: 1.2222222222rem;
		text-align: left;
		margin-top: 0;
	}
	.ask-page .section-form .form-wrapper {
		width: 100%;
	}
	.ask-page .section-form .wrapper .title {
		font-size: 2.1111111111rem;
		text-align: left;
		margin-top:0.9444444444rem;
		margin-bottom: 0;
	}
	.ask-page .section-form .form-wrapper .paragraph {
		padding: 0;
		p {
			font-size: 0.6666666667rem;
			text-align: left;
		}
	}
	.ask-page .section-form .italic{
		text-align: left;
		font-size:0.6666666667rem;
	}
	.ask-page .section-form .form-wrapper .paragraph p.italic{
		text-align: left;
		font-size:0.6666666667rem;
		margin-top:0.6666666667rem;
		@include ru-light;
	}
	.ask-page .section-form .form-wrapper .paragraph label{
		text-align: left;
		font-size:0.6666666667rem;
	}
	.ask-page .section-form input{
		width: 100%;
	}
	
	span.wpcf7-list-item{
		margin-left: 0 !important; 
	}
	.ask-page .section-form{
		// background-size:80%;
		background-position: 80%;
	}
	.ask-page .section-form textarea{
		width: 100%;
	}
	.wpcf7-submit {
		width: 60% !important;
	}
	
	.single-faq .inner.answer {
		line-height: 1.5;
		padding: 0.8333333333rem;
		// font-size: 0.6666666667rem;
		font-size: 0.8333333333rem;
	}
	.contact-page .container {
		width: calc(100% - 2.5rem);
	}
	.contact-page .container .wrapper-content {
		width: 100%;
		h1 {
			margin-top: 7.5rem;
			margin-left: 0;
			margin-bottom: 2rem;
		}
		&.v-center {
			top: 0;
			transform: translateY(0);
			position: relative;
		}
	}
	.contact-page {
		.column:nth-child(1) {
			margin: 0;
		}
		.column:nth-child(2) {
			margin: 0;
		}
	}
	.column:nth-child(2) {
		margin: 0;
	}
	.contact-page .container .wrapper-content .wrapper-information {
		margin-left: 0;
	}
	.cookie-page .intro-page-wrapper .subtitle {
		padding: 0 2.5rem;
	}
	.cookie-page .content-page {
		width: calc(100% - 2.5rem);
	}
	.policy-page .content-page {
		width: calc(100% - 2.5rem);
	}
	.policy-page .two-column-wrapper {
		columns: 1;
	}
	.policy-page .two-column-wrapper .text-column {
		.subtitle {
			font-size: 0.8888888889rem;
			margin: 1rem 0;
		}
		p {
			font-size: 0.7777777778rem;
		}
	}
	.policy-page .block-text-and-title h3 {
		font-size: 0.8888888889rem;
		margin: 1rem 0;
	}
	.policy-page .block-text-and-title {
		margin: 1rem auto;
	}
	.policy-page .block-text-and-title p {
		font-size: 0.7777777778rem;
	}
	.policy-page .two-column-wrapper .text-column {
		height: auto;
	}
	.rituale-quotidiano .section-intro .wrapper-img {
		width: 100%;
		left: -80px;
	}
	.rituale-quotidiano .section-intro .spirale-wrapper {
		width: 100%;
	}
	.rituale-quotidiano .section-intro .wrapper-text.right {
		top: 40%;
		z-index: 100;
		left: 38%;
	}
	.section-easy-drink .wrapper-img {
		display: none;
		&.mobile{
			opacity: 1;
    		transform: translateY(0);
    		margin: auto;
			display: block;
			position: static;
			width: auto;
			height:50vh;
			img{
				width: 80%;
				position:static;
				transform:translateY(0);
				opacity:1;
				margin-top:20px;
			}
		}
	}

	.section-easy-drink {
		height: auto;
		.column.right {
			position: relative;
			left: 0;
			margin: 0;
			padding:50px 0;
			transform: translateY(0);
		}
	}
	
	.section-easy-drink .wrapper-content .text-container .paragraph {
		/* max-width: 26.6875rem; */
		max-width: unset;
		margin: 0;
		font-size: 0.7777777778rem;
		line-height: 1.5;
		padding: 0;
		p {
			font-size: 0.7777777778rem;
			line-height: 1.5;
		}
	}
	.section-easy-drink .wrapper-content .text-container {
		width: 100%;
		margin: 0 auto;
		padding: 0 2rem;
	}
	.section-easy-drink .wrapper-content .text-container .title {
		font-size: 3rem;
		text-align: center;
		label {
			position: static;
			p {
				font-size: 1rem;
			}
		}
	}
	.section-cosa-dicono .wrapper-video {
		width: calc(100% - 2.5rem);
		margin: 2.5rem auto;
		position: relative;
	}
	.page-template-essential_is footer {
		position: relative;
		width: 100%;
	}
	.section-cosa-dicono h3 {
		font-size: 2rem;
	}
	.scroll-wrapper-tips.sticky {
		display: none;
	}
	.mobile {
		.statistic-box-essenziale {
			padding: 2.5rem 0;
			height:60vh;
			
			.wrapper-content {
				.column {
					width: 100%;
					margin-left: 0;
					margin-right: 0;
					&:nth-child(2) {
						margin-left: 0;
					}
					.single-statistic {
						.text-no-number {
							p {
								font-size: 0.7777777778rem;
								line-height: 1.5;
							}
						}
						.text {
							width: 63%;
							margin-left: 5%;
							p {
								font-size: 0.7777777778rem;
								line-height: 1.5;
							}
						}
						.number {
							width: 32%;
							margin-left: 0;
							margin-right: 0;
							font-size: 2.6666666667rem;
							strong {
								font-size: 2.1875rem;
							}
						}
					}
				}
				width: calc(100% - 2rem);
				margin:0 auto;
				.label-title{
					margin-bottom:0;
					padding-bottom: 0;
				}
				.text-container {
					width: 100%;
					padding: 0;
					.title{
						label{
							display:none;
						}
					}
					.paragraph {
						width: 100%;
						padding: 0;
						font-size: 0.7777777778rem;
						p {
							font-size: 0.7777777778rem;
							text-align: left;
						}
					}
				}
				.statistic-essenziale-wrapper{
					.main-carousel{
						top:0;
					}
					.single-statistic{
						.number{
							font-size: 3rem;
							margin-right: 0;
						}
						.text{
							font-size: .8rem;
							max-width: 75%;
						}
					}
				}
			}
		}
		.boario-mobile {
			.wrapper-content {
				padding: 2.2222222222rem 0;
			}
			.paragraph {
				padding: 0 1rem;
				p {
					font-size: 0.7777777778rem;
					line-height: 1.5;
					text-align: left;
				}
			}
			.btn-primary {
				margin: 3rem auto 0;
			}
			.wrapper-logo {
				width: calc(100% - 2rem);
				margin: 0 auto;
				padding: 1rem 0;
				text-align: center;
				img {
					width: 70%;
				}
			}
			.wrapper-claim {
				height: 50vh;
				@include bg-cover;
				text-align: center;
				position: relative;
				background-position: center left;
				h3 {
					width:100%;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					font-size: 1.9444444444rem;
					color: #FFF;
					font-family: 'Rubik';
					font-weight: 300;
					font-style: normal;
				}
			}
		}
	}
	.mineral-list .single-mineral .name {
		font-size: 0.7777777778rem;
	}
	.mineral-list .single-mineral .number {
		font-size: 0.9444444444rem;
	}
	.mineral-list .single-mineral .label {
		font-size: 0.6666666667rem;
	}
	.page-template-essential_is {
		.intro {
			padding: 100px 0 0;
			text-align: center;
			position: relative;
			min-height: 100vh;
			.subtitle {
				margin-left: 0;
				padding: 0 1.1111111111rem;
			}
			.wrapper-bottle {
				// width: 22vw;
				text-align: center;
				margin: 0 auto 2rem;
			}
			.scroll {
				display: none;

				position: relative;
				@include ru-light;
				text-transform: uppercase;
				color: #b0b7bc;
				font-size: 0.6666666667rem;
				// bottom: 3.8888888889rem;
				top: 86vh;
				position: absolute;
				width: 100%;
				&:after {
					background-image: url('/assets/mobile_scroll_arrow.png');
					background-size: contain;
					background-position: center;
					width: 19px;
					height: 30px;
					position: absolute;
					content: "";
					bottom: -32px;
					left: calc(50% - 7.5px);
				}
			}
		}
	}
	.statistic-box-essenziale .wrapper-content .text-container .title {
		font-size: 2.7777777778rem;
		line-height: 2.2222222222rem;
	}
	#cookiebar {
		width: 100%;
		text-align: center;
	}
	#cookiebar .more-info {
		width: 100%;
		margin-top: 1.1111111111rem;
	}
	#cookiebtn-close {
		&.btn-primary {
			margin-left: auto;
			margin-right: auto;
			display: block;
			width: 60%;
		}
	}
	#cookiebar .cookie-bar-text {
		width: 100%;
		display: block;
		font-size: 14px;
		font-family: 'Rubik';
		font-weight: 500;
		font-style: normal;
		padding: 0;
	}
	.contact-page {
		height: auto;
	}
	.contact-page .container .wrapper-content .wrapper-information h2,
	.contact-page .container .wrapper-content .wrapper-information h3 {
		font-size: 1.2222222222rem;
	}
	.contact-page .container .wrapper-content .wrapper-information p {
		font-size: 0.7777777778rem;
		line-height: 1.5;
	}
	.policy-page .intro-page-wrapper {
		padding: 5.5rem 0 0;
		margin-bottom: 2rem;
	}
	.intro-page-wrapper .subtitle {
		margin-top: 2rem;
	}
	.policy-page .block-text-and-title .title {
		font-size: 1.2222222222rem;
	}
	.cookie-page .content-page .block-text-and-title {
		width: 100%;
		margin: 0;
	}
	.cookie-page .content-page .block-text-and-title .title {
		font-size: 1.2222222222rem;
	}
	.cookie-page .content-page .single-faq .question {
		font-size: 0.8333333333rem;
		padding: 0.8333333333rem;
		padding-right: 2rem;
	}
	.cookie-page .content-page .block-text-and-title .text p {
		font-size: 0.7777777778rem;
		line-height: 1.5;
	}
	.cookie-page {
		.intro-page-wrapper {
			padding: 5.5rem 0 2rem;
		}
	}
	.essenziale_is-page {
		.paragraph {
			padding: 0;
			p {
				font-size: 0.7777777778rem;
				line-height: 1.5;
				text-align: center;
			}
		}
	}
	.wrapper-content .title {
		font-size: 48px;
		line-height: 0.8;
		label {
			font-size: 1.25rem;
			line-height: 1;
			bottom: 0;
			margin: -20px 0 20px 20px;
		}
	}
	.paragraph {
		padding: 0 1.1111111111rem 0 0;
		p {
			font-size: 0.7777777778rem;
			line-height: 1.5;
			text-align: center;
		}
	}
	.mobile-wrapper-animation-content {
		padding-bottom: 2.7rem;
		margin-top:2rem;
		.wrapper-content {
			// font-size: 2.7777777778rem;
			// background-image: url("/assets/bottiglia_ita_tagliata.png");
			// background-position: right top;
			// background-size: 20%;
			// background-repeat: no-repeat;
			// padding-left: 20px;
			width: 90%;
			margin:0 auto;
			.paragraph {
				&.size-s {
					// max-width: 60%;
				}
				p {
					text-align: left;
				}
			}
		}
		.title {
			.label {
				line-height: 1;
			}
			&.size-s {
				width: 55%;
				word-break: break-word;
				margin-top: 50px;
			}
		}
		.mineral-list {
			margin-left: 0;
			.single-mineral {
				opacity: 1;
				transform: scale3d(1, 1,1); 
				margin:5px;
			}
		}
	}
	.wrapper-content a.link {
		color: $dark-blue;
		&:after {
			background-color: transparent;
			position: absolute;
			content: "";
			width: 50px;
			height: 19px;
			right: -55px;
			top: calc(50% - 9.5px);
			background-image: url(../assets/arrow-link.png);
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
	.statistic-box-essenziale .wrapper-content .statistic-essenziale-wrapper .column .single-statistic .text-no-number {
		opacity: 0.7;
		p {
			margin-bottom: 0;
		}
	}
	.fe-for-page {
		.section-slide{
			height:50vh;
			.block-slide-wrapper {
				.single-block-slide {
					.image-slide-block{
						background-size:cover;
					}
					&.left {
						.image-slide-block{
							background-position: -85vw;
						}
						
					}
					&.right{
						background-position: -15vw;
					}
					&.to-close {
						.image-slide-block {
							background-image: none !important;
						}
					}
				}
			}
		}
	}
	.rituale-quotidiano .quotes-section .wrapper-content .text-container{
		position: static;
	}
	.rituale-quotidiano .quotes-section .statistic-essenziale-wrapper .main-carousel, 
	.rituale-quotidiano .quotes-section .statistic-essenziale-wrapper .main-carousel .carousel-cell{
		height:50vh;
		width:100%;
		padding: 0;
		.quote-image{
			margin-top:0;
		}
		.quote{
			font-size:1.5rem;
		}
	}
	.fade-up,
	.fade-up-1{
		opacity: 1;
	}
	[data-aos^=fade][data-aos^=fade]{
		opacity: 1;
	}
	.subtitle{
		font-size: 1.2rem;
	}
	.wrapper-slider-concorso .owl-concorso .item-cont{
		width: 100%;
		display: block;
		text-align: center;
	}
	.wrapper-img-slide.item-cont{
		margin-top:20px;
	}
	.wrapper-slider-concorso .owl-concorso .item{
		width: 80%;
	}
	.wrapper-slider-concorso .owl-concorso .wrapper-content-slide .btn-primary{
		margin:20px auto;
	}
	.wrapper-slider-concorso .owl-concorso .item-cont .title{
		font-size:40px;
		line-height: 38px;
	}
	.wrapper-slider-concorso .owl-concorso .owl-nav .owl-next{
		right: 0 !important;
	}
	.wrapper-slider-concorso .owl-concorso .owl-nav .owl-prev{
		left: 0 !important;
	}

	.section-vitasnella-block{
		padding-top:30px;
		padding-bottom: 0;
	}
	.section-vitasnella-block .wrapper-content .text-container{
		position: relative;
		width: 100%;
		top:0;
		left:0;
		text-align: center;
		transform: none;
		margin-bottom: 30px;
	}
	.section-vitasnella-block .wrapper-content .text-container .title label{
		width: 100%;
	}
	.section-vitasnella-block .btn-primary{
		margin:0 auto;
	}
	.section-vitasnella-block .wrapper-content .text-container .paragraph{
		margin-top:60px;
	}
	.section-vitasnella-block .wrapper-img{
		position: relative;
		width: 100%;
		display: block;
		min-height: 240px;
	}
	.soddisfatti-page .container .text-container{
		width: 100%;
		float: none;
		position: relative;
		transform: unset;
	}
	.soddisfatti-page .container .image-wrapper{
		width: 100%;
    float: none;
    /* height: 50%; */
    position: relative;
    top: 0;
	left: 0;
	text-align: center;
	}
	.soddisfatti-page .container .left-margin{
		padding-left: 0;
	}
	.soddisfatti-page .btn-primary{
		width: auto;
	}
	.soddisfatti-page .container{
		width: 90%;
		margin:0 auto;
		height: auto;
	}
	.wrapper-slider-concorso .owl-concorso .item-cont.wrapper-content-slide{
		width: 100%;
		margin-right: 0;
	}
	.wrapper-slider-concorso .owl-concorso .item-cont.wrapper-img-slide{
		width: 100%;
		margin-left:auto;
		margin-right: auto;
	}
	.section.cosa-dicono-video .title{
		font-size: 2.85rem;
    	line-height: 3rem;
	}
	.page-template-rituale{
		.section-intro{
			.wrapper-img{
				display: none;
			}
		}
		
	}
	.paragraph{
		ul{
			position: relative;
			li{
				font-size: 0.7777777778rem;
				line-height: 1.5;
				padding-left: 1.6rem;
				&:before{
					position: absolute;
    				left: 0;
				}
			}
		}
	}
}

@media	screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
	.section-find-a-store-mobile{
		.img{
			&:before{
				left:30vh;
				font-size:4rem;
			}
			&.handy{
				&:before{
					left:30vh;
    				font-size: 1.2rem;
				}
			}
		}
	}
	.page-template-essential_is .intro .wrapper-bottle{
		width: 10vw;
	}
	.mobile .statistic-box-essenziale{
		height: 60vh;
	}
}