#privacy{


    #articleTitle{

    }

    .wrapper-content{
        max-width:1024px;
        padding:50px;
        margin:auto;

        p{
            padding: 1.25rem 0 1.25rem 2.5rem;
            text-align: left;
            line-height: 1.4rem;
            color: #151f6d;
        }
    }
}