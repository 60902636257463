﻿//hooks
@function pem($pixels) {
  @return #{$pixels/16.0}em
}

//FONTS
@mixin ro-thin {
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
}

@mixin ro-light {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

@mixin ro-regular {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

@mixin ro-medium {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

@mixin ro-bold {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

@mixin ru-regular {
    font-family: 'Rubik';
    font-weight: normal;
    font-style: normal;
}

@mixin ru-bold {
    font-family: 'Rubik';
    font-weight: bold;
    font-style: normal;
}

@mixin ru-light {
    font-family: 'Rubik';
    font-weight: 300;
    font-style: normal;
}

@mixin ru-medium {
    font-family: 'Rubik';
    font-weight: 500;
    font-style: normal;
}

@mixin ru-bold {
    font-family: 'Rubik';
    font-weight: bold;
    font-style: normal;
}

@mixin ru-italic-bold {
    font-family: 'Rubik';
    font-weight: bold;
    font-style: italic;
}

@mixin ru-italic {
    font-family: 'Rubik';
    font-weight: normal;
    font-style: italic;
}

//VAR
$bg-grey:#dce5ea;
$font-grey:#5b5d64;
$light-cyan : #b4dae8;
$font-blue:#151f6d;
$dark-blue:#009cdd;
$light-blue:#091114;
$footer-bg :#f0f8fa;
$pink:#fbd5d3;
$pink-light :#fef0f2;
@mixin navigation {
    line-height: 80px;
    a {
        color: $font-grey;
        &:hover {
            color: $dark-blue;
        }
        li {
            // margin: 0 31px;
            // margin: 0 1.9375rem;
            margin: 0 3.6rem;
            text-align: center;
            display: inline-block;
        }
    }
}

@mixin general-image-wrapper {
    text-align: center;
    position: absolute;
    z-index: 100;
    top: calc(50% + 80px);
    left: 10vw;
    transform: translateY(-50%);
    img {
        margin: 0 auto;
    }
}

@mixin bg-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@mixin text-label {
    .title {
        @include ru-light;
        // color: rgba($font-grey, 0.1);
        color: $light-cyan;
        line-height: 72px; // color: #f2f2f2;
        position: relative;
        z-index: 10;
        text-transform: uppercase;
        // font-size: 6.25rem;
        font-size: 4.2vw;
        margin-bottom: 0;
        ///opacity: .6;
        label {
            @include ru-medium;
            font-size: 1.875rem;
            // position: absolute;
            text-transform: none;
            // bottom: 0px;
            // top: calc(100% - 35px);
            // left: 20px;
            color:$font-blue;
            // line-height: 1;
            display: block;
            line-height: 1.5;
            margin: -45px 0 20px 20px;
        }
    }
    @media screen and (max-width: 991px), screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
        .title {
            label {
                line-height: 1;
                margin: -20px 0 20px 20px;
            }
        }
    }
}

@mixin clearfix{
    content: "";
    display: table;
    clear: both;
}