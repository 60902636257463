//MAX WIDTH 1440

@media screen and (max-width:1536px){
	.mcIndicatoreRisoluzione{
		width: 1px;
	}
}
@media screen and (max-height: 854px) and(max-width: 1536px) {
    .contact-page .container .wrapper-content .image-wrapper{
		img{
			width: 40%;
		}
	}
}
@media screen and (max-width: 1440px) {
	.mcIndicatoreRisoluzione {
		width: 1px;
	}
	html,
	body {
		@include ru-light;
		background-color: #FFFFfF;
		color: $font-grey;
		font-size: 12px;
		width: 100%;
		height: 100%;
	}
	.section-boario .wrapper-claim {
		left: 5vw;
		width: 34vw;
	}
	.fe-for-page .block-slide-wrapper .single-block-slide.left .label-slide {
		img {
			width: 75%;
		}
	}
	.fe-for-page .block-slide-wrapper .single-block-slide.right .label-slide {
		img {
			width: 75%;
		}
	} 
	
	.fe-for-page .block-slide-wrapper .single-block-slide.left .image-slide-block{
		background-position: -82vw;
	}
	.fe-for-page .block-slide-wrapper .single-block-slide.left.to-close .image-slide-block{
		left: -64vw;
	}
	.fe-for-page .block-slide-wrapper .single-block-slide.right .image-slide-block{
		background-position: -14vw;
	}

	.fe-for-page .block-slide-wrapper .single-block-slide.right .image-slide-block.open{
		background-position: 80%;
	}
	
	// .sustainability-section .wrapper-image-video {
	// 	display: inline-block;
	// 	width: 60vw;
	// 	margin-left: 5vw;
	// 	position: relative;
	// 	text-align: center;
	// }

	// .contact-page {
	// 	img {
	// 		max-width: 40%;
	// 	}
	// }
	
	// .wrapper-post-box .single-post .wrapper-text-post{
	// 	height: 210px;
	// }
	.section-boario .wrapper-content{
		padding: 20px 30px 20px 4%;
	}

	.mineral-list .single-mineral{
		margin-top:0;
	}
	
	.statistic-box-essenziale .source{
		bottom:2vh;
	}

	.section-boario {
		.wrapper-content {
			padding: 20px 5%;
		}
		.wrapper-claim {
			width: 35vw;
			padding: 0;
		}
	} 
	
	// .statistic-box-essenziale .wrapper-content {
	// 	margin-top: 50px;
	// }
	// .statistic-box-essenziale .wrapper-content .label-title {
	// 	margin-top: 3vh;
	// }
	.page-template-store-locator{
		.intro-wrapper{
			padding: 20.5rem 0 4.0625rem;
		}
	}
}

/*

MAX WIDTH 1366
*/

@media screen and (max-width: 1366px) {
	html,
	body {
		font-size: 12px;
	}
	.mcIndicatoreRisoluzione {
		width: 2px;
	} // .wrapper-video-block .wrapper-img {
	// 	width: 40vw;
	// 	img {
	// 		max-width: 100%;
	// 	}
	// }
	nav .logo-wrapper{
		top: -10px;
		transform: translate3d(-50%, 0, 0) scale(.7);
		// transform: translate3d(0, 0, 0) scale(.7);
	}
	nav .main-menu-wrapper:before{
		transform: translate3d(-50%, 0, 0) scale(1);
		// transform: translate3d(0, 0, 0) scale(1);
		// bottom: -45px;
		bottom: -55px;
	}
	nav.one-line-menu .main-menu-wrapper:before{
		transform: translate3d(-50%, -15px, 0) scale(1);
		// bottom:-60px;
		// bottom: -70px;
	}
	
	// .wrapper-video-block .container .wrapper-content .title{
 //    	font-size: 7.072rem;
	// }
	.statistic-box-essenziale .wrapper-content .statistic-essenziale-wrapper .column .single-statistic .number{
		font-size:4rem;
		strong{
			font-size: 2.75rem;
		}
	}
	.statistic-box-essenziale .wrapper-content .text-container .title {
		br {
			display: none;
		}
	}
	// .statistic-box-essenziale .wrapper-content .label-title {
	// 	margin-bottom:50px;
	// }
	.statistic-box-essenziale .wrapper-content .statistic-essenziale-wrapper .column .single-statistic{
		margin: 1rem 0 0;
	}
	.statistic-box-essenziale .wrapper-content .statistic-essenziale-wrapper .column .single-statistic .text{
		font-size:1.2rem;
	}
	.statistic-box-essenziale .wrapper-content .statistic-essenziale-wrapper{
		margin-bottom: 0;
	}
	.rituale-quotidiano .section-intro .wrapper-text .ritual-info{
		margin-top: 2em;
	}
	.mineral-list {
		// margin-top: 10px;
		margin-left: 0;
    	width: 120%;
	}
	.home-bubble-box.box1 .mineral-list{
		width:24vw;
	}
	.mineral-list .single-mineral{
		width:100px;
		height:100px;
	}
	.mineral-list.move-up .single-mineral{
		margin:10px;
	}
	.mineral-list .single-mineral .name {
		font-size: 1.2rem;
	}
	.mineral-list .single-mineral .number {
		font-size: 2rem;
	}
	
	.mineral-list .single-mineral .label {
		font-size: 1rem;
	}
	.video-home-bg-wrapper .bg-acqua{
		bottom:-55vh;
	}
	.parte-scroll-controllato .animazione-scriptata .testo-1 {
		bottom: 40%;
	}
	.ask-page .section-expert-video .wrapper-content .title {
		left: 40vw;
	}
	.ask-page .section-expert-video .wrapper-content .text-content {
		left: 48vw;
	}
	.video-home-bg-wrapper .video-box {
		right: -30%;
	}
	.section-boario .wrapper-claim {
		width: 34vw;
	}
	.expert-home-block .wrapper-content .title {
		font-size: 6.3125rem;
	}
	.fe-for-page .block-slide-wrapper .single-block-slide.left .label-slide {
		img {
			width: 70%;
		}
	}
	.fe-for-page .block-slide-wrapper .single-block-slide.right .label-slide {
		img {
			width: 70%;
		}
	}
	.home-glass-box {
		height: 15vw;
	}
	.text-conteiner {
		.btn-primary {
			margin-left: 20px;
		}
	}
	.rituale-quotidiano .section-intro .wrapper-img {
		top: calc(50% + 40px);
	}
	
	.parte-scroll-controllato.essenziale_is-page .testo-2{
		top:14%;
	}
	.section-boario .wrapper-content{
		top: 56%;
		left: 52%;
		transform: translateY(-50%);
		width: 600px;
		padding: 30px 90px;
	}

	.main-carousel,
	.carousel-cell{
		//height:130px;
	}
	.carousel-cell{
		padding: 0 100px;
	}
	// .statistic-box-essenziale .wrapper-content .statistic-essenziale-wrapper .single-statistic .text{
	// 	font-size: 2.5rem;
	// }
	.home-bubble-box.box4{
		top:53vh;
	}
	.home-bubble-box.box4,
	.home-bubble-box.box4b{
		left:28%;
	}
	
	.parte-scroll-controllato.essenziale_is-page .testo-3{
		width:31%;
	}
	.soddisfatti-page .container .image-wrapper .fardello{
		width: 80%;
	}
	.wrapper-find-store .single-formato .wrapper-img{
		height: 56vh;
	}
}
@media screen and (max-width: 1280px){
	.statistic-box-essenziale .wrapper-content .main-carousel{
		margin-top:0;
	}
}

@media screen and (max-width: 1280px) and(min-height : 900px){

	.video-home-bg-wrapper .maschera-spirale{
		background-size:cover;
	}
	.home-glass-box{
		bottom: 23%;
    	left: 52%;
	}
	.section-boario .bottiglia-wrapper{
		width: 22vw;
	    left: 40vw;
    	top: calc(17vh + 40px);
	}
	.video-home-bg-wrapper .bg-acqua{
		    bottom: -35vh;
	}
	.parte-scroll-controllato .animazione-scriptata .background.bg-water-fs{
			background-size:cover;
	}
	.main-carousel{
		top:100px;
	}
	.section-easy-drink .wrapper-img img.bicchieri{
		top:40vh;
	}
}

@media screen and (max-width: 1280px) {
	.home-glass-box{
		bottom:0;
	}
	.video-home-bg-wrapper .bg-acqua{
		bottom:-60vh;
	}


	.statistic-box-essenziale .wrapper-content .label-title{
		padding:0px 0 30px 30px;
		// margin-bottom:50px;
	}
	.fe-for-page .block-slide-wrapper .single-block-slide.left .image-slide-block{
		background-position: -90vw;
	}
	.fe-for-page .block-slide-wrapper .single-block-slide.right .image-slide-block{
		background-position: -20vw;
	}
	.rituale-quotidiano .quotes-section{
		padding-top: 0;
	}
	.rituale-quotidiano .quotes-section .statistic-essenziale-wrapper .main-carousel,
	.rituale-quotidiano .quotes-section .statistic-essenziale-wrapper .main-carousel .carousel-cell{
		height:80vh;
	}
	.flickity-page-dots{
		bottom:50px;
	}
}
@media screen and (max-width: 1200px) {
	nav .logo-wrapper{
		top: 0px;
    	-webkit-transform: translate3d(0%, 0, 0) scale(1);
		transform: translate3d(0%, 0, 0) scale(1);
	}
}

@media screen and (max-width: 1024px) {
	// nav .logo-wrapper{
	// 	top: 0px;
 //    	-webkit-transform: translate3d(0%, 0, 0) scale(1);
	// 	transform: translate3d(0%, 0, 0) scale(1);
	// }
	.video-home-bg-wrapper .maschera-spirale{
		background-size: cover;
    	background-position: -50px;
	}
	.video-home-bg-wrapper .bg-acqua{
		bottom:-50vh;
	}
	.home-glass-box{
		bottom:10vh;
	}	
	.section-boario .bottiglia-wrapper{
		width: 21vw;
		top: calc(20vh + 40px);
	}
	.section-boario .wrapper-content{
		max-width:400px;
	}
	.parte-scroll-controllato .animazione-scriptata .background.bg-water-fs{
		background-size:cover;
	}
	.mineral-list{
		width:100%;
	}
	.home-bubble-box.box4b{
		top: 32vh;
	}
	.statistic-box-essenziale .wrapper-content .statistic-essenziale-wrapper .single-statistic .number{
		font-size:6rem;
	}
	.statistic-box-essenziale .wrapper-content .statistic-essenziale-wrapper .single-statistic .text {
    	font-size: 1.8rem;
    	line-height: 1.2em;
	}
	.flickity-page-dots{
		bottom:0;
	}
	.main-carousel, .carousel-cell{
		height:200px;
	}
	.page-template-essential_is .section-boario .wrapper-content{
		max-width: 400px;
	}
	.fe-for-page .block-slide-wrapper .single-block-slide.left .image-slide-block{
		background-position: -115vw;
	}
	.fe-for-page .block-slide-wrapper .single-block-slide.right .image-slide-block{
			background-position: -36vw;
	}
	.fe-for-page .block-slide-wrapper .single-block-slide.left .image-slide-block.close{
		background-position: -134vw;
	}
	.rituale-quotidiano .section-intro .wrapper-text .ritual-info img{
		max-width:90%;
	}
	.rituale-quotidiano .quotes-section .statistic-essenziale-wrapper .main-carousel .carousel-cell .quote-image{
		margin-top:80px;
		width:150px;
		height:150px;
	}
	.rituale-quotidiano .quotes-section .statistic-essenziale-wrapper .flickity-page-dots{
		bottom:80px;
	}
	.sustainability-section .container .wrapper-content .title{
		font-size: 5.25rem;
		line-height: 5rem;
		width:auto;	
	}
	.page-post-single .wrapper-content .content img{
		width:auto;
		max-width:100%;
	}
}


