﻿//ROBOTO

@font-face {
	font-family: "Roboto";
	src: local(Roboto Thin), url("../../css/fonts/roboto/Roboto-Thin.eot");
	src: url("../../css/fonts/roboto/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("../../css/fonts/roboto/Roboto-Thin.woff2") format("woff2"), url("../../css/fonts/roboto/Roboto-Thin.woff") format("woff"), url("../../css/fonts/roboto/Roboto-Thin.ttf") format("truetype");
	font-weight: 200;
}

@font-face {
	font-family: "Roboto";
	src: local(Roboto Light), url("../../css/fonts/roboto/Roboto-Light.eot");
	src: url("../../css/fonts/roboto/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../../css/fonts/roboto/Roboto-Light.woff2") format("woff2"), url("../../css/fonts/roboto/Roboto-Light.woff") format("woff"), url("../../css/fonts/roboto/Roboto-Light.ttf") format("truetype");
	font-weight: 300;
}

@font-face {
	font-family: "Roboto";
	src: local(Roboto Regular), url("../../css/fonts/roboto/Roboto-Regular.eot");
	src: url("../../css/fonts/roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../../css/fonts/roboto/Roboto-Regular.woff2") format("woff2"), url("../../css/fonts/roboto/Roboto-Regular.woff") format("woff"), url("../../css/fonts/roboto/Roboto-Regular.ttf") format("truetype");
	font-weight: 400;
}

@font-face {
	font-family: "Roboto";
	src: url("../../css/fonts/roboto/Roboto-Medium.eot");
	src: url("../../css/fonts/roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../../css/fonts/roboto/Roboto-Medium.woff2") format("woff2"), url("../../css/fonts/roboto/Roboto-Medium.woff") format("woff"), url("../../css/fonts/roboto/Roboto-Medium.ttf") format("truetype");
	font-weight: 500;
}

@font-face {
	font-family: "Roboto";
	src: url("../../css/fonts/roboto/Roboto-Bold.eot");
	src: url("../../css/fonts/roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../../css/fonts/roboto/Roboto-Bold.woff2") format("woff2"), url("../../css/fonts/roboto/Roboto-Bold.woff") format("woff"), url("../../css/fonts/roboto/Roboto-Bold.ttf") format("truetype");
	font-weight: 700;
}


//RUBIK


@font-face {
    font-family: 'Rubik';
    src: url('fonts/rubik/Rubik-Black.woff2') format('woff2'),
        url('fonts/rubik/Rubik-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/rubik/Rubik-Regular.woff2') format('woff2'),
        url('fonts/rubik/Rubik-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/rubik/Rubik-Bold.woff2') format('woff2'),
        url('fonts/rubik/Rubik-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/rubik/Rubik-Italic.woff2') format('woff2'),
        url('fonts/rubik/Rubik-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/rubik/Rubik-LightItalic.woff2') format('woff2'),
        url('fonts/rubik/Rubik-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/rubik/Rubik-MediumItalic.woff2') format('woff2'),
        url('fonts/rubik/Rubik-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/rubik/Rubik-Light.woff2') format('woff2'),
        url('fonts/rubik/Rubik-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/rubik/Rubik-BlackItalic.woff2') format('woff2'),
        url('fonts/rubik/Rubik-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/rubik/Rubik-Medium.woff2') format('woff2'),
        url('fonts/rubik/Rubik-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/rubik/Rubik-BoldItalic.woff2') format('woff2'),
        url('fonts/rubik/Rubik-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}