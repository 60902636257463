@-ms-keyframes spin { 
	from { 
		-ms-transform: rotate(0deg); 
	} to { 
		-ms-transform: rotate(360deg); 
	}
}
@-moz-keyframes spin { 
	from { 
		-moz-transform: rotate(0deg); 
	} to { 
		-moz-transform: rotate(360deg); 
	}
}
@-webkit-keyframes spin { 
	from { 
		-webkit-transform: rotate(0deg); 
	} to { 
		-webkit-transform: rotate(180deg); 
	}
}
@keyframes spin { 
	from { 
		transform: rotate(0deg); 
	} to { 
		transform: rotate(180deg); 
	}
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
  100%{
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}