﻿html,
body {
	@include ru-light;
	background-color: #FFFFfF;
	color: $font-grey;
	font-size: 14px;
	width: 100%;
	height: 100%; // overflow: hidden;
}

body {
	&.normal-scroll {
		overflow: auto;
	}
}

.no-scroll-normal {
	overflow: hidden;
}

img {
	max-width: 100%;
}

ul {
	margin: 1em 0;
	padding-left: 1.5em;
	list-style: disc;
	list-style-position: outside;
	li {
		padding-left: .5em;
		margin-bottom: 10px;
	}
}

.no-wrap {
	white-space: nowrap;
}

.animation {
	// display: none;
	opacity: 0;
	-webkit-transition: all 1s ease-in-out;
	-moz-transition: all 1s ease-in-out;
	-ms-transition: all 1s ease-in-out;
	-o-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
	transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
	&.active {
		opacity: 1;
	}
}

// .background{
// 	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,f3f9fb+55,e8f4f8+100 */
// background: #ffffff; /* Old browsers */
// background: -moz-linear-gradient(top, #ffffff 0%, #f3f9fb 55%, #e8f4f8 100%); /* FF3.6-15 */
// background: -webkit-linear-gradient(top, #ffffff 0%,#f3f9fb 55%,#e8f4f8 100%); /* Chrome10-25,Safari5.1-6 */
// background: linear-gradient(to bottom, #ffffff 0%,#f3f9fb 55%,#e8f4f8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e8f4f8',GradientType=0 ); /* IE6-9 */
// }
.preloaderApp {
	z-index: 6000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; //background-image: url("../../assets/sfondoSemiTrasparente.png");
	text-align: center;
	background-color: #FFFFFF;
	.preloader-container {
		display: inline-block;
		vertical-align: middle;
		.clipLoader {
			padding: 0;
			background-color: #FFFFFF;
			width: 90px;
			top: 50%;
			position: absolute;
			left: calc(50% - 45px);
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

.vhelper {
	display: inline-block;
	vertical-align: middle;
	height: 100%;
}

.mainApp {
	position: relative;
	margin: 0 auto;
	height: 100%;
}

.parte-scroll-controllato {
	pointer-events: none;
	position: absolute;
	z-index: 300;
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	overflow: hidden;
	.animazione-scriptata {
		pointer-events: none;
		position: absolute;
		width: 100%;
		height: 100%;
		.background {
			pointer-events: none;
			width: 100%;
			height: 100%;
			transition: background 1s linear;
			&.bg-water-fs {
				opacity: 0;
				background-image: url('../assets/bg-water-fs.jpg');
				background-position: center;
				background-size: 100%;
				background-repeat: no-repeat;
				background-position: center 80px;
				animation: fadeIn .4s ease-in-out;
				animation-fill-mode: both;
				animation-delay: .5s;
			}
		}
		.bottiglia {
			position: absolute; //max-width: 564px;
			z-index: 100;
			.bottiglia-frame {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%; //background-image: url("../assets/bottiglia/bottiglia0.png");
				background-position: center center;
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
		.blocco-testo {
			position: absolute; // display: none; 
			pointer-events: all;
			opacity: 0; // overflow: auto;
			z-index: 20;
		}
		.testo-0 {
			// background-color: aqua;
			width: 37%; // height: 27%; // left: 11%;
			left: 6.25rem;
			// top: 39%;
			// top 100% forzato così da essere fuori dalla viewport al primo scroll
			top: 100%;
			z-index: 100;
			height: 40%;
		}
		.testo-1 {
			// background-color: #4cff00;
			width: 34%;
			height: 20%; // left: 7%;
			left: 6.25rem;
			bottom: 30%;
			height: 40%;
			// top: 48%;
			// top 100% forzato così da essere fuori dalla viewport al primo scroll
			top: 100%;
			.btn-primary {
				margin-left: 2.5rem;
			}
		}
		.testo-2 {
			// background-color: #ff6a00;
			width: 30%; // height: 20%; // left: 7%;
			left: 6.25rem;
			// top: 24%;
			// top 100% forzato così da essere fuori dalla viewport al primo scroll
			top: 100%;
			height: 40%;
			.btn-primary {
				margin-left: 2.5rem;
				min-width: 16.0625rem;
			}
		}
		.testo-3 {
			// background-color: #ffd800;
			width: 34%; // height: 20%; // left: 7%;
			left: 6.25rem;
			// top: 10%;
			// top 100% forzato così da essere fuori dalla viewport al primo scroll
			top: 100%;
			height: 40%;
			z-index: 1000;
			.btn-primary {
				margin-left: 2.5rem;
			}
		}
	}
	.full-frame {
		display: none;
		position: absolute;
		width: 100%;
		height: 100%; // padding: 80px 0 0;
	}
}

.essenziale_is-page {
	.wrapper-find-store {
		.single-formato {
			opacity: 0;
			transform: translateY(50px);
			-webkit-transition: all 1s ease;
			-moz-transition: all 1s ease;
			-ms-transition: all 1s ease;
			-o-transition: all 1s ease;
			transition: all 1s ease;
			transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
		}
	}
	.section-boario {
		.boario-text {
			-webkit-transition: all 1.5s ease;
			-moz-transition: all 1.5s ease;
			-ms-transition: all 1.5s ease;
			-o-transition: all 1.5s ease;
			transition: all 1.5s ease;
			transform: translateY(-50%); //transform: translateY(0) !important;
			transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
			&.move-up {
				opacity: 1;
				transform: translateY(-50%);
			}
		}
	}
}

.parte-scroll-controllato {
	&.essenziale_is-page {
		.wrapper-find-store {
			padding: 0;
			pointer-events: all;
		}
		.testo-0 {
			// background-color: aqua;
			width: 37%;
			height: 27%;
			left: 11%;
			top: 39%;
		}
		.testo-1 {
			// background-color: #4cff00;
			width: 34%;
			height: 20%;
			left: 7%;
			top: 38%;
		}
		.testo-2 {
			// background-color: #ff6a00;
			width: 34%;
			height: 55%;
			left: 49%;
			top: 14%;
			em {
				color: $font-grey;
				font-weight: 300;
			}
		}
		.testo-3 {
			// background-color: #ffd800;
			width: 39%;
			height: 72%;
			left: 7%;
			top: 16%;
		}
		.section-boario .wrapper-content {
			height: auto; //opacity: 1;
		}
	}
}

.parte-scroll-misto {
	position: absolute;
	width: 100%;
	height: 100%; // background-color: darkcyan;
	background-color: #FFF;
	display: none;
	z-index: 1000;
}

.parte-scroll-normale {
	height: auto; // background: linear-gradient(#b8f3ea, aqua);
	z-index: 100;
}

.library {
	display: none;
}

.mcIndicatoreRisoluzione {
	width: 0px;
}

//PARTE CUSTOM
h1 {
	@include ru-light;
	font-size: 4.85rem;
	line-height: 4rem; // color: $dark-blue;
	color: $dark-blue;
}

b,
strong {
	@include ru-medium;
}

.uppercase {
	text-transform: uppercase;
}

.subtitle {
	margin-top: 35px;
	margin-left: 40px;
	@include ru-regular;
	line-height: auto;
	font-size: 2.2rem;
	color: $font-blue;
}

.modal-open {
	overflow: hidden;
}

.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 5050;
	display: none;
	overflow: hidden;
	outline: 0;
	.wrapper-iframe {
		left: 25%;
		width: 50%;
		height: 60vh;
		margin-top: 20vh;
		margin-left: auto;
		margin-right: auto;
	}
	.iframeVideo {
		position: relative;
		width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.close-modal {
		position: absolute;
		left: 74%;
		top: 15vh;
		cursor: pointer;
	}
}

.modal.fade .modal-dialog {
	-webkit-transition: -webkit-transform 0.3s ease-out;
	transition: -webkit-transform 0.3s ease-out;
	-o-transition: -o-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out, -o-transform 0.3s ease-out;
	-webkit-transform: translate(0, -25%);
	-o-transform: translate(0, -25%);
	transform: translate(0, -25%);
}

.modal.show .modal-dialog {
	-webkit-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
}

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: 10px;
}

.modal-content {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	outline: 0;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 5010;
	background-color: rgba(0, 0, 0, 0.8);
}

.modal-backdrop.fade {
	opacity: 0;
}

.modal-backdrop.show {
	opacity: 0.5;
}

.modal-header {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 15px;
	border-bottom: 1px solid #eceeef;
}

.modal-title {
	margin-bottom: 0;
	line-height: 1.5;
}

.modal-body {
	position: relative;
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 15px;
}

.modal-footer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding: 15px;
	border-top: 1px solid #eceeef;
}

.modal-footer> :not(:first-child) {
	margin-left: .25rem;
}

.modal-footer> :not(:last-child) {
	margin-right: .25rem;
}

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 500px;
		margin: 30px auto;
	}
	.modal-sm {
		max-width: 300px;
	}
}

@media (min-width: 992px) {
	.modal-lg {
		max-width: 800px;
	}
}

.btn-primary,
.wpcf7-submit {
	pointer-events: all;
	text-align: center;
	@include ru-light;
	font-size: 1.15rem;
	display: inline-block; // width: 225px;
	min-width: 14.0625rem;
	height: 48px;
	line-height: 48px;
	color: white;
	background-color: $dark-blue;
	-webkit-box-shadow: 0 5px 15px 0px #cecece;
	box-shadow: 0 5px 15px 0px #cecece;
	cursor: pointer;
	margin: 25px 0;
	padding: 0 20px;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	-webkit-appearance: none ;
	&:hover {
		background-color: $font-grey;
		color: #FFF;
	}
}

.wpcf7-submit {
	padding: 0 !important;
	width: 20% !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

div.wpcf7-validation-errors {
	display: none !important;
}

.testo-0 {
	.btn-primary {
		margin-top: 60px; // margin-top: 100px;
	}
}

.paragraph {
	padding: 1.25rem 0 1.25rem 2.5rem;
	text-align: left;
	color: $font-blue;
	ul {
		@include ru-light;
		padding-left: 1rem;
		list-style-type: disc;
		li {
			list-style-type: disc;
			margin: 0.5rem 0;
			list-style: none;
			display: block;
			font-size: 1.2715rem;
			&:before {
				width: 1.5rem;
				height: 1.5rem;
				content: '';
				display: inline-block;
				background: url('../assets/list-bullet.png');
				background-size: contain;
				background-position: center;
				vertical-align: middle;
				margin-right: 5px;
			}
			&:hover {
				&:before {
					animation: spin 2s linear infinite;
				}
			}
		}
	}
	p {
		@include ru-light; // font-size: 0.9375rem;
		// font-size: 1.0715rem;
		font-size: 1.2715rem;
		line-height: 25px; 
		// max-width: 400px;
		em {
			font-size: .8em;
			font-weight: normal;
			line-height: 1.4;
			display: inline-block;
		}
	}
}

.wrapper-step-text {
	.wrapper-title {
		background-color: transparent;
		position: relative;
		height: 7.25rem;
	}
	.title {
		font-size: 1.875rem;
		z-index: 100;
		position: absolute;
		top: 50%;
		left: 20px;
		@include ru-medium;
	}
}

.testo-1 {
	.wrapper-title {
		&:before {
			position: absolute;
			content: "01";
			top: 0;
			left: 0;
			color: $light-cyan;
			font-size: 8.25rem;
			opacity: 0.6;
		}
		.title {
			color: $font-blue;
			font-size: 2.2rem;
		}
	}
}

.testo-2 {
	.wrapper-title {
		&:before {
			position: absolute;
			content: "02";
			top: 0;
			left: 0;
			color: $light-cyan;
			font-size: 8.25rem;
			opacity: 0.6;
		}
		.title {
			color: $font-blue;
			font-size: 2.2rem;
		}
	}
}

.testo-3 {
	.wrapper-title {
		&:before {
			position: absolute;
			content: "03";
			top: 0;
			left: 0;
			color: $light-cyan;
			font-size: 8.25rem;
			opacity: 0.6;
		}
		.title {
			color: $font-blue;
			font-size: 2.2rem;
		}
	}
}

.section-boario {
	background-position: center bottom;
	background-size: cover;
	background-repeat: no-repeat;
	z-index: 100;
	.wrapper-content {
		pointer-events: none;
		max-width: 500px; // width: 500px;
		overflow: hidden;
		opacity: 0;
		-webkit-transition: transform 1.3s, opacity .9s;
		-moz-transition: transform 1.3s, opacity .9s;
		-ms-transition: transform 1.3s, opacity .9s;
		-o-transition: transform 1.3s, opacity .9s;
		transition: transform 1.3s, opacity .9s;
		transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
		transition-delay: .5s; // padding: 20px 5vw;
		padding: 2rem 5rem;
		background-color: rgba(255, 255, 255, 0.95);
		position: absolute;
		top: 50%;
		left: 53vw;
		// height: 386px;
		width: 500px;
		transform: translateY(-50%);
		text-align: center; // opacity: 0; // height: 471px;
		&.boario-animated-in {
			opacity: 1;
			transform: translateY(-50%);
		} // &.boario-animated-out {
		// 	opacity: 0;
		// }
		&.move-up {
			transform: translateY(0);
			opacity: 1;
		}
		.paragraph {
			p {
				margin: 20px 0;
			}
		}
	}
	.bottiglia-wrapper {
		position: absolute;
		text-align: center; // height: 80vh;
		width: 20vw;
		left: 40vw;
		top: calc(10vh + 40px); // left: calc(50vw - 200px);
		z-index: 100; // opacity: 0;
		-webkit-transition: all 1.5s ease;
		-moz-transition: all 1.5s ease;
		-ms-transition: all 1.5s ease;
		-o-transition: all 1.5s ease;
		transition: all 1.5s ease;
		img {
			// height: 100%;
			width: auto;
			max-width: 100%;
		}
	}
	.paragraph {
		padding: 0;
	}
	.wrapper-claim {
		opacity: 0;
		text-align: left;
		top: 50%;
		position: absolute;
		left: 10vw; // max-width: 500px;
		width: 35vw;
		transform: translateY(50%) !important;
		-webkit-transition: transform 1.3s, opacity .9s;
		-moz-transition: transform 1.3s, opacity .9s;
		-ms-transition: transform 1.3s, opacity .9s;
		-o-transition: transform 1.3s, opacity .9s;
		transition: transform 1.3s, opacity .9s;
		transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
		text-shadow: 6px 5px 11px #000;
		h3 {
			font-size: 4.25rem;
			color: #FFF;
			@include ru-light;
		}
		&.move-up {
			opacity: 1;
			transform: translateY(-50%) !important;
		}
	}
}

.shadow {
	text-shadow: 6px 5px 11px #000;
}

.wrapper-slider-concorso {
	.owl-theme .owl-dots .owl-dot span {
		background: #FFF !important;
		width: 15px;
		height: 15px;
		margin: 0 5px 25px !important;
	}
	background-color:#b4dae8;
	.owl-concorso {
		.item {
			font-size: 0;
			width: 70%;
			margin: 0 auto;
			padding: 50px 0 0;
		}
		.item-cont {
			// width: 50%;
			display: inline-block;
			vertical-align: middle;
			&.wrapper-content-slide {
				width: 37%;
				margin-right: 3%;
			}
			&.wrapper-img-slide {
				width: 60%;
			}
			.title {
				font-size: 64px;
				@include ru-light;
				color: #51aedf;
				line-height: 62px;
			}
		}
		.wrapper-content-slide {
			.btn-primary {
				margin-bottom: 0;
			}
			.claim {
				text-transform: uppercase;
				color: #FFF;
				margin-top: 30px;
				font-size: 21px;
			}
			.logo {
				max-width: 320px;
			}
		}
		.owl-nav {
			.owl-next {
				width: 40px !important;
				height: 40px !important;
				top: calc(50% - 20px) !important;
				background-image: url('/assets/arrow_grey_next.png') !important;
				background-size: 15px !important;
				background-repeat: no-repeat !important;
				right: 50px !important;
				border-radius: 50% !important;
				background-color: #fff !important;
				padding: 0 !important;
				opacity: 1 !important;
				background-position: 15px center;
			}
			.owl-prev {
				width: 40px !important;
				height: 40px !important;
				top: calc(50% - 15px) !important;
				background-image: url('/assets/arrow_grey_prev.png') !important;
				background-size: 15px !important;
				background-repeat: no-repeat !important;
				border-radius: 50% !important;
				background-color: #fff !important;
				padding: 0 !important;
				opacity: 1 !important;
				background-position: 10px center;
				left: 50px !important;
			}
		}
	}
}

.video-home-bg-wrapper {
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%; // background-color: red;
	height: 100%;
	z-index: 1;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	.video-box {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 0;
		right: -20%;
		.iframeVideo {
			height: 100%;
			z-index: 1;
			position: absolute;
		}
		video {
			height: 100%;
			z-index: 1;
			position: absolute;
		}
	}
	.maschera-spirale {
		width: 100%;
		height: 100%; //background-size: contain;
		background-size: 100%;
		background-position: center right;
		background-repeat: no-repeat;
		background-image: url('/assets/maschera_1920.png');
		z-index: 10;
		position: absolute;
	}
	.bg-acqua {
		width: 100%;
		height: 100%;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url('/assets/bg_acqua.png');
		z-index: 10;
		position: absolute;
		opacity: 0;
		bottom: -50vh; // transform: scale(.9);
		transform: translate3d(0, 60%, 0);
		transition: transform 1s ease-in-out, opacity .6s ease-in-out;
		&.visible {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
}

.home-bubble-box {
	overflow: hidden;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	width: 50vw;
	height: 20vw;
	overflow: auto;
	position: absolute;
	top: 30vh; // top: 32vh;
	left: 45%;
	z-index: 1000;
	opacity: 0;
	zoom: 0;
	transform: scale(0);
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	&.bubble-animated-in {
		overflow: hidden;
		opacity: 1;
		zoom: 1;
		transform: scale(1);
	}
}

.home-bubble-box.box1 {
	overflow: hidden;
	top:calc(50% - 80px);
	.wrapper-content {
		width: 24vw;
		margin: auto;
		.paragraph {
			padding: 0;
			text-align: center;
		}
	}
	.mineral-list {
		margin: auto;
		display: flex;
		padding:10px 0;
		width: 24vw;
		.single-mineral {
			margin: auto;
		}
		// .single-mineral:nth-child(1) {
		// 	animation-delay: 1s;
		// }
		// .single-mineral:nth-child(2) {
		// 	animation-delay: 1.2s;
		// }
	}
	&.bubble-animated-in {
		overflow: hidden;
		height: auto;
		.single-mineral {
			animation: zoomIn .4s ease-in-out;
			animation-fill-mode: both;
		}
	}
}

.home-bubble-box.box2 {
	overflow: hidden;
	background-image: url("/assets/bubble.png");
	&.benefits {
		left: 30%;
		top: 50vh;
		z-index: 0;
	}
}

.home-bubble-box.box3 {
	background-image: url("/assets/calendario.png");
}

.home-bubble-box.box4 {
	background-image: url("/assets/bubble4b.png");
	top: 51vh;
	left: 29%;
	height: 15vw;
	z-index: 0;
	transition-delay: .5s;
}

.home-bubble-box.box4b {
	background-image: url("/assets/bubble4.png");
	top: 23vh;
	left: 29%;
	height: 15vw;
	z-index: 0;
	transition-delay: .7s;
}

.home-bubble-box.box2 .rotateBubble {
	background-image: url("/assets/bubble-arrow.png");
	position: relative;
	display: block;
	z-index: 99999999;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	height: 16vw;
	left: 0;
	top: 23px; //opacity: 0;
	transition: opacity .5s linear;
	&.spin {
		//opacity:1;
		animation: spin 1.8s ease-in-out forwards;
		animation-iteration-count: 1;
	}
}

// .fade-up,
// .fade-up-1 {
// 	opacity: 0;
// 	-webkit-transition: all 1.5s ease-in-out;
// 	-moz-transition: all 1.5s ease-in-out;
// 	-ms-transition: all 1.5s ease-in-out;
// 	-o-transition: all 1.5s ease-in-out;
// 	transition: all 1.5s ease-in-out;
// }
//.full-opacity{}
.home-calendario-box {
	background-image: url("/assets/calendario.png");
	background-size: contain;
	background-position: center bottom;
	background-repeat: no-repeat;
	width: 15vw;
	height: 15vw;
	overflow: auto;
	position: absolute;
	bottom: 40%;
	left: 67vw;
	z-index: 1000;
	opacity: 0;
	zoom: 0;
	transform: scale(0);
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	&.calendario-animated-in {
		opacity: 1;
		zoom: 1;
		transform: scale(1);
	}
}

.home-glass-box {
	background-image: url("/assets/bicchieri.png");
	background-size: contain;
	background-position: center bottom;
	background-repeat: no-repeat;
	width: 30vh;
	height: 30vh;
	overflow: auto;
	position: absolute; // bottom: -2%;
	bottom: 2%;
	left: 55%;
	z-index: 1000;
	opacity: 0;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	&.bicchiere-animated-in {
		opacity: 1;
	}
}

.wrapper-content {
	pointer-events: none;
	&--no-block {
		pointer-events: auto;
	}
	a {
		&.link {
			pointer-events: all;
			color: #b0b7bc;
			font-size: 14px;
			@include ru-bold;
			position: relative;
			margin: 20px 0;
			-webkit-transition: all 0.5s ease-in-out;
			-moz-transition: all 0.5s ease-in-out;
			-ms-transition: all 0.5s ease-in-out;
			-o-transition: all 0.5s ease-in-out;
			transition: all 0.5s ease-in-out;
			&::after {
				position: absolute;
				content: "";
				width: 20px;
				height: 2px;
				background-color: $dark-blue;
				margin-left: 0;
				right: -30px;
				top: calc(50% - 1px); // background-image: url('../assets/line_link.png');
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				-ms-transition: all 0.2s ease-in-out;
				-o-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
			}
			&:hover {
				color: $dark-blue;
				&::after {
					background-color: transparent;
					position: absolute;
					content: "";
					width: 50px;
					height: 19px;
					right: -65px;
					top: calc(50% - 9.5px);
					background-image: url('../assets/arrow-link.png');
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
				}
			}
		}
	}
	p {
		margin: 20px 0;
		max-width: 100%;
	}
	.title {
		@include ru-light;
		font-size: 6.072rem; // color: rgba($font-grey, 0.1);
		color: $light-cyan;
		// line-height: 1.5;
		line-height: 72px; // color: #f2f2f2;
		position: relative;
		z-index: 10;
		text-transform: uppercase;
		label {
			@include ru-medium; // color: $font-grey;
			color: $font-blue;
			font-size: 2.1rem;
			// position: absolute;
			// position: relative;
			display: block;
			text-transform: none;
			// bottom: -12px;
			// left: 20px;
			line-height: 1.5;
			margin: -45px 0 20px 20px;
		}
	}
	&.white {
		.title {
			color: rgba(255, 255, 255, 0.1);
		}
		label {
			color: #FFF;
			line-height: 1.1;
		}
	}
}

.lateral-navigation-circle-wrapper {
	position: fixed;
	right: 30px;
	height: 60vh;
	top: calc(20vh + 40px);
	z-index: 99;
	ul {
		height: 60vh;
		position: relative;
		padding: 0;
		margin: 0;
		li {
			display: block;
			width: 0;
			height: 30vh;
			position: relative;
			padding-left: 0;
			margin-bottom: 0;
			&:nth-child(3) {
				&:after {
					display: none;
				}
			}
			&:after {
				position: absolute;
				content: "";
				width: 1px;
				height: 30vh;
				background-color: #b0b7bc;
			}
			.fp-tooltip {
				text-transform: uppercase;
				font-size: 0.8125rem;
				color: #b0b7bc;
				@include ru-medium;
				height: auto;
				width: 130px;
				position: absolute;
				display: block;
				left: -160px;
				text-align: right;
			}
			.circle {
				width: 14px;
				height: 14px;
				border-radius: 50%;
				background-color: #b0b7bc;
				display: block;
				position: absolute;
				top: 0;
				left: -7px;
				z-index: 100;
				transform: scale(1);
				-webkit-transition: transform .2s ease-in-out, opacity .2s ease-in-out;
				-moz-transition: transform .2s ease-in-out, opacity .2s ease-in-out;
				-ms-transition: transform .2s ease-in-out, opacity .2s ease-in-out;
				-o-transition: transform .2s ease-in-out, opacity .2s ease-in-out;
				transition: transform .2s ease-in-out, opacity .2s ease-in-out;
				&:after {
					position: absolute;
					content: "";
					width: 10px;
					height: 10px;
					background-color: $dark-blue;
					border: 1px solid #FFF;
					border-radius: 50%;
					top: 1px;
					left: 1px;
					background-color: $dark-blue;
					transform: scale(.1);
					opacity: 0;
					-webkit-transition: transform .2s ease-in-out, opacity .2s ease-in-out;
					-moz-transition: transform .2s ease-in-out, opacity .2s ease-in-out;
					-ms-transition: transform .2s ease-in-out, opacity .2s ease-in-out;
					-o-transition: transform .2s ease-in-out, opacity .2s ease-in-out;
					transition: transform .2s ease-in-out, opacity .2s ease-in-out;
				}
			}
			&.active {
				.fp-tooltip {
					color: $dark-blue;
				}
				.circle {
					// width: 20px;
					// height: 20px;
					// left: -10px;
					transform: scale(1.7);
					background-color: $dark-blue; // &:nth-child(1) {
					// 	&:after {
					// 		top: 2.8px;
					// 	}
					// }
					&:after {
						// position: absolute;
						// content: "";
						// width: 10px;
						// height: 10px;
						// background-color: $dark-blue;
						// border: 2px solid #FFF;
						// border-radius: 50%;
						// top: 2.7px;
						// left: 2.5px; 
						// top: 2.5px;
						// left: 2.5px;
						transform: scale(1);
						opacity: 1;
					}
				}
			}
		}
	}
}

.scroll-wrapper-tips {
	pointer-events: all;
	cursor: pointer;
	@include ru-medium;
	font-size: 0.75rem;
	-moz-transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	text-transform: uppercase;
	color: #b0b7bc;
	position: absolute;
	z-index: 100;
	height: 0;
	left: 20px; // top: calc(50vh - 10px);
	bottom: 15vh;
	letter-spacing: 1px;
	&:before {
		position: absolute;
		z-index: 50;
		content: "";
		width: 19px;
		height: 140px;
		background-image: url('../assets/arrow-scroll.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
		-moz-transform: rotate(90deg);
		-webkit-transform: rotate(90deg);
		-o-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
		left: 10px;
		top: -63px;
	}
}

.wrapper-find-store {
	background-color: rgba($pink, 0.3);
	height: 100%;
	height: 100vh;
	clear: both;
	padding: 0;
	.row-btn {
		text-align: center;
		clear: both;
		margin-top: 20px;
		.btn-primary {
			margin: 0 10px;
			display: inline-block;
		}
	}
	&.ita {
		.single-formato {
			.wrapper-content-fardello {
				margin-top: 10vh;
			}
			height: 100%;
			&:hover {
				background-color: rgba($pink, 0.6);
			}
		}
	}
	.single-formato {
		margin-top: 15vh;
		opacity: 0;
		display: block;
		float: left; //margin-top: 13vh; // margin-top: calc(20vh - 40px);
		text-align: center;
		width: 50%;
		height: 100vh;
		transform: translateY(50px);
		-webkit-transition: transform .5s ease-in-out, opacity .5s ease-in-out;
		-moz-transition: transform .5s ease-in-out, opacity .5s ease-in-out;
		-ms-transition: transform .5s ease-in-out, opacity .5s ease-in-out;
		-o-transition: transform .5s ease-in-out, opacity .5s ease-in-out;
		transition: transform .5s ease-in-out, opacity .5s ease-in-out;
		transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
		padding-bottom: 2.5rem;
		transition-delay: .5s;
		transform: translateY(100px);

		&.coming-soon{
			opacity:.5 !important;
		}

		.title {
			@include ru-light;
			font-size: 30px;
			margin: 0;
			color: $font-blue;
		}
		.subtitle {
			font-size: 1.3rem;
			@include ru-light;
			margin: 20px 0 0;
		}
		.wrapper-img {
			height: 60vh;
			width: 40vw;
			background-repeat: no-repeat;
			background-position: center 0; // margin: 30px auto 0;
			margin: 0 auto 0;
			background-size: contain;
			position: relative;
			&:before {
				opacity: 0;
				content: "1L";
				display: block;
				width: 18vh;
				height: 18vh;
				background: $font-blue;
				color: white;
				font-size: 5rem;
				@include ru-bold;
				border-radius: 100%;
				line-height: 18vh;
				position: absolute;
				// top: 8vh;
				// left: 8vh;
				top: 15%;
				left: 5%;
			}
			&.handy {
				&:before {
					content: "400 ML";
					width: 15vh;
					height: 15vh;
					font-size: 2rem;
					line-height: 15vh;
					top: 20vh;
					left: 10vh;
				}
			}
			img {
				width: auto;
				max-width: 100%;
			}
		}
		&.fade-up,
		&.fade-up-1 {
			&.move-up {
				opacity: 1;
				transform: translateY(0px);
				.wrapper-img {
					&:before {
						animation: zoomIn .4s linear;
						animation-fill-mode: forwards;
						animation-delay: 1s;
					}
				}
			}
		}
	}
}

.expert-home-block {
	width: 100%;
	height: 430px;
	padding: 100px 0;
	.wrapper-content {
		pointer-events: none;
		width: 80vw;
		height: 100%;
		margin: 0 auto;
		position: relative;
		.wrapper-img {
			position: absolute; // width: 25vw;
			width: 420px;
			img {
				max-width: 100%;
				height: auto;
				width: 100%;
			}
		}
		.title {
			font-size: 7.3125rem;
			line-height: 6.4375rem;
			@include ru-light;
			color: $font-grey;
			opacity: 0.18;
			position: absolute;
			left: 22vw;
			top: 0;
		}
		.text-content {
			position: absolute;
			bottom: 1.625rem;
			left: 32vw;
			.name-doc {
				font-size: 1.875rem;
				@include ru-light;
				color: $font-grey;
			}
			.role {
				font-size: 1.125rem;
				@include ru-medium;
				color: $font-grey;
				margin-bottom: 34px;
			}
			.text {
				font-size: 0.9375rem;
				@include ru-italic;
				font-weight: 300;
			}
		}
	}
}

.wrapper-home-statistic {
	position: relative;
	.wrapper-content {
		width: 100%;
		min-height: 650px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		.wrapper-text {
			width: 50%;
			position: absolute;
			top: 10%;
			left: 10vw;
			.percentage-number {
				font-size: 11.875rem;
				@include ru-light;
				font-style: italic;
				color: $light-cyan;
				z-index: 1;
			}
			.box-text {
				z-index: 10;
				position: absolute; // width: 500px;
				max-width: 32.25rem;
				left: 176px;
				top: 38%;
			}
			.source-statistic {
				color: $font-grey;
				@include ru-light;
				font-size: 11px;
				margin: 20px 0;
			}
			.title {
				@include ru-italic;
				color: $font-blue;
				font-size: 2.5rem;
				line-height: 3rem;
				margin: 0 0 20px;
				text-transform: none;
				opacity: 1;
				strong {
					font-weight: bold;
					font-style: italic;
				}
			}
		}
	}
}

.wrapper-video-block {
	padding: 6.25rem 0;
	&:after {
		@include clearfix;
	}
	.container {
		width: calc(100% - 8.75rem);
		margin: 0 auto;
		&:after {
			content: "";
			display: table;
			clear: both;
		}
		.wrapper-image-video {
			// width: 64%;
			width: 60%;
			margin-left: 4%;
			float: left;
			position: relative;
			img {
				width: 100%;
			}
			.icon-play {
				position: absolute;
				top: calc(50% - 46px);
				left: calc(50% - 46px);
				cursor: pointer;
			}
		}
		.btn-primary {
			margin-left: 2.5rem;
		}
		.wrapper-content {
			width: 35%;
			margin-right: 1%;
			float: left;
			.title {
				// font-size: 8.072rem;
				font-size: 4.2vw;
				text-align: justify;
				// line-height: 6rem;
				text-transform: uppercase; // color: #FFF;
				// color: rgba($font-grey, 0.1); // opacity: 1;
				color: $light-cyan;
				// word-wrap: break-word;
				label {
					@include ru-bold; // color: $font-grey;
					color: $font-blue; //nero per simulare il grigio scuro
					bottom: 10px;
				}
			}
		}
	}
}

.wrapper-animation-block {
	// opacity: 0;
	transform: translateY(50px);
	-webkit-transition: all 1.5s ease;
	-moz-transition: all 1.5s ease;
	-ms-transition: all 1.5s ease;
	-o-transition: all 1.5s ease;
	transition: all 1.5s ease;
	transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.statistic-box-essenziale {
	position: relative;
	padding: 100px 0;
	background: #a6d1e2;
	background: -moz-linear-gradient(top, #a6d1e2 0%, #71b8dd 44%, #429fd7 100%);
	background: -webkit-linear-gradient(top, #a6d1e2 0%, #71b8dd 44%, #429fd7 100%);
	background: linear-gradient(to bottom, #a6d1e2 0%, #71b8dd 44%, #429fd7 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6d1e2', endColorstr='#429fd7', GradientType=0);
	background-image: url('../assets/numeri_bg.jpg');
	background-size: cover;
	background-position: center right;
	.scroll-wrapper-tips {
		color: #FFF;
	}
	.spirale-wrapper {
		background-size: contain;
		background-position: right;
		background-repeat: no-repeat;
		width: 90vw;
		height: 180vh;
		position: absolute;
		top: -80%;
		right: 0;
		z-index: 100;
		opacity: 0.8;
	}
	.text-container {
		// -webkit-transition: all 1.5s ease;
		// -moz-transition: all 1.5s ease;
		// -ms-transition: all 1.5s ease;
		// -o-transition: all 1.5s ease;
		// transition: all 1.5s ease;
		transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
	}
	.source {
		@include ru-medium;
		position: absolute;
		bottom: 5vh;
		left: 50%;
		font-size: 1.2em;
		transform: translateX(-50%);
		color: white;
	}
	.wrapper-content {
		// width: calc(100% - 14.375rem);
		width: 95%;
		max-width: 1366px;
		margin: 0 auto 0;
		color: #FFF;

		.main-carousel{
			    // margin-top: 100px;
			    margin-top: 2%;
		}
		.wrapper-title-sub {
			transform: translateY(50px);
			-webkit-transition: all 1s ease;
			-moz-transition: all 1s ease;
			-ms-transition: all 1s ease;
			-o-transition: all 1s ease;
			transition: all 1s ease;
			transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
		}
		.text-container {
			.paragraph {
				width: 40vw;
				color: white;
			}
			.title {
				@include ru-light;
				// font-size: 6.072rem;
				font-size: 4.2vw;
				color: white;
				line-height: 72px; // color: #f2f2f2;
				position: relative;
				z-index: 10;
				text-transform: uppercase;
				opacity: 1;
				label {
					font-size: 1.875rem;
					// position: absolute;
					text-transform: none;
					// left: 20px;
					color: #FFF;
					// margin-top: 0;
					// bottom: -50px;
				}
			}
		}
		.label-title {
			font-size: 1.25rem;
			padding: 0 2.25rem;
			@include ru-bold; // opacity: 0.7;
		}
		.statistic-essenziale-wrapper {
			position: relative;
			top: 0;
			.main-carousel {
				padding: 20px;
			}
			.single-statistic {
				flex-direction: column;
				justify-content: center;
				padding: 0;
				text-align: center;
				margin: 0 10px;
				max-width: 100%;
				em {
					font-weight: 300;
					color: #FFF;
				}
				&:after {
					@include clearfix;
				}
				.number {
					font-size: 5rem;
					color: rgba(255, 255, 255, 1); // width: 30%;
					@include ru-italic-bold;
					margin-right: 1.875rem;
					strong {
						font-size: 3.75rem;
					}
				}
				.text {
					@include ru-italic; // font-size: 1.5rem;
					max-width: 50%;
					font-size: 2rem;
					line-height: 2.5rem;
					color: #FFF;
					font-weight: 300;
					p {
						margin: 0;
					}
				}
				.text-no-number {
					@include ru-light;
					font-size: 1.25rem;
				}
			}
		}
	}
}

.mineral-list {
	clear: both;
	// margin-top: 10px;
	margin-top: -20px;
	margin-left: 0;
	width: 120%;
	// width: 24vw;
	// margin-left: 3rem;
	&:after {
		@include clearfix;
	}
	.single-mineral {
		-webkit-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3);
		opacity: 0;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
		float: left;
		// width: 150px;
		// height: 150px;
		width: 120px;
		height: 120px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		// margin: 2rem;
		margin: 10px;
		margin-left: 0;
		text-align: center;
		color: #FFF;
		background-color: $dark-blue;
		border-radius: 100%;
		transition: all .5s ease-in-out;
		.name {
			@include ru-light;
			color: $font-blue;
			// font-size: 1.4rem;
			font-size: 1.2rem;
		}
		.number {
			@include ru-light;
			// font-size: 3rem;
			font-size: 2rem;
		}
		.label {
			@include ru-medium;
			// font-size: 0.9375rem;
			font-size: 1rem;
			color: $font-blue;
		}
		// &:nth-child(even) {
		// 	margin-left: 0.3rem;
		// 	margin-right: 0;
		// }
	}
	&.move-up {
		.single-mineral {
			animation: zoomIn .4s ease-in-out;
			animation-fill-mode: both;
			animation-delay: .5s;
		}
		// @for $i from 1 to 4 {
		// 	.single-mineral:nth-child(#{$i}) {
		// 		animation-delay: $i * 0.25s;
		// 	}
		// }
	}
}

.scroll-wrapper-tips {
	&.bottom {
		right: 20px;
		left: unset;
		bottom: 15vh;
		top: unset;
	}
	&.sticky {
		position: fixed;
	}
}

.rituale-quotidiano {
	.section-intro {
		.spirale-wrapper {
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			width: 42%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
			opacity: 0.8;
		}
		position: relative;
		height: 100vh;
		.wrapper-img {
			@include general-image-wrapper;
			width: 30vw;
			img {
				max-width: 100%;
			}
		}
		.wrapper-text {
			&.right {
				position: absolute;
				top: 50%;
				left: 50vw;
				transform: translateY(-50%);
			}
			.ritual-info {
				margin-left: 45px;
				margin-top: 4em;
				img {
					max-width: 100%;
				}
			}
		}
	}
	.quotes-section {
		background-image: url('../assets/quotes_bg.jpg');
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		position: relative;
		.wrapper-content {
			.text-container {
				// position: absolute;
				.title {
					color: rgba(255, 255, 255, .3);
					label {
						bottom: -10px;
						left: 50px;
					}
				}
			}
		}
		.statistic-essenziale-wrapper {
			position: relative;
			top: 0;
			.main-carousel {
				// height: 60vh;
				// padding: 50px;
				padding: 20px;
				.carousel-cell {
					display: flex;
					flex-direction: column;
					justify-content: start;
					// height: 60vh;
					min-height: 350px;
					height: auto;
					width: 100%;
					padding: 20px;
					.quote-image {
						width: 200px;
						height: 200px;
						display: table;
						border-radius: 100%;
						background-size: cover;
						background-position: center;
						box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
						margin-bottom: 20px;
					}
					.quote {
						@include ru-regular;
						font-style: italic;
						font-size: 2.3rem;
						text-align: center;
						max-width: 800px;
					}
				}
			}
			.flickity-prev-next-button {
				top: 70%;
			}
			.flickity-page-dots {
				left: 50%;
				transform: translateX(-50%);
				.dot {
					width: 18px;
					height: 18px;
				}
			}
		}
	}
}

.column {
	display: block;
	width: 48%;
	float: left;
	// margin-left: 0%;
	margin-left: 1%;
	margin-right: 1%;
	padding: 0;
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	&:nth-child(1) {
		margin-left: 0%;
		margin-right: 2%;
	}
	&:nth-child(2) {
		margin-left: 2%;
		margin-right: 0;
	}
	&.right {
		// float: right;
		left: 50%;
	}
	&.white {
		color: #FFF;
	}
}

.v-center {
	position: absolute;
	top: 50%; // left: 50vw;
	transform: translateY(-50%);
}

.section-easy-drink {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: rgba($light-cyan, 0.2);
	.wrapper-content {
		// margin-left: 10vw;
		.text-container {
			width: 90%;
			.paragraph {
				max-width: 40rem;
			}
			.title {
				font-size: 7.3125rem;
				line-height: 82px;
				label {
					// line-height: 1.1; 
					// bottom: 0;
					left: 1.875rem;
					p {
						margin: 0;
					}
				}
			}
		}
	}
	.paragraph {
		margin-top: 0.9375rem;
		margin-left: 0.9375rem;
	}
	.wrapper-img {
		position: relative;
		@include general-image-wrapper;
		transform: translateY(-50%);
		width: 40vw;
		top: 0;
		// left: 0;
		left: 70px;
		img {
			max-width: 100%;
			&.fardello,
			&.calendario,
			&.bicchieri {
				opacity: 0;
				position: absolute;
			}
			&.fardello {
				width: 80%;
				top: 10vh;
				left: 0;
			}
			&.calendario {
				width: 45%;
				top: 15vh;
				// right: -50px;
				right: -40px;
				animation-delay: .5s;
			}
			&.bicchieri {
				width: 37%;
				top: 50vh;
				// right: -40px;
				right: -30px;
				animation-delay: 1s;
			}
		}
		&.aos-animate {
			img {
				animation: zoomIn .4s linear;
				animation-fill-mode: forwards; //animation-delay: .5s;
				&.calendario {
					animation-delay: .6s;
				}
				&.bicchieri {
					animation-delay: .3s;
				}
			}
		}
	}
}

.section-vitasnella-block {
	position: relative;
	width: 100%;
	@include bg-cover;
	background-image: url('/assets/sfondo-sfumato.png');
	min-height: 560px;
	.wrapper-content {
		.text-container {
			position: absolute;
			left: 8%;
			width: 40%;
			top: 50%;
			transform: translateY(-50%);
			.paragraph {
				// max-width: 25.6875rem;
				margin-top: 30px;
				margin-bottom: 30px;
				margin-left: 0.9375rem;
				color: #FFF;
				font-size: 15px;
			}
			.title {
				font-size: 116px;
				label {
					font-size: 30px;
					// position: absolute;
					text-transform: none;
					// bottom: -35px;
					// left: 1.875rem; // color: #FFF;
					color: #151f6d;
					// line-height: 1.2;
					line-height: 1.5;
					margin: -40px 0 20px 20px;
					p {
						margin: 0;
					}
				}
			}
		}
	}
	.btn-primary {
		width: fit-content;
		max-width: 300px;
		padding-left: 1.25rem;
		padding-right: 1.25rem;
		box-shadow: none;
		margin-left: 3.5rem;
		background-color: #FFF;
		color: #5b5d64;
		margin-top: 0;
		&:hover {
			background-color: #5b5d64;
			color: #FFF;
		}
	}
	.wrapper-img {
		width: 60%;
		min-height: 560px;
		position: absolute;
		right: 0;
		top: 0;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}
}

section.cosa-dicono-video {
	padding-bottom: 80px;
	.embed-responsive {
		position: relative;
		display: block;
		width: 80%;
		padding: 0;
		overflow: hidden;
		height: 48vw;
		margin: 0 auto;
		&:before {
			display: block;
			content: "";
		}
		&:after {
			display: block;
			clear: both;
			content: "";
		}
		.embed-responsive-item {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 0;
			&:before {
				padding-top: 56.25%;
			}
		}
	}
	.title {
		padding: 80px 0;
		font-family: 'Rubik';
		font-weight: 300;
		font-style: normal;
		font-size: 4.85rem;
		line-height: 4rem;
		color: #009cdd;
		text-align: center;
	}
}

em {
	color: #51aedf;
	@include ru-italic;
	font-weight: 500;
}

.italic-font {
	@include ru-italic;
	font-weight: inherit;
}

footer {
	line-height: 10px;
	padding: 20px 10vw;
	padding-top: 25px;
	background-color: $footer-bg;
	@include ru-light;
	ul {
		margin: 0;
		display: inline-block;
		font-size: 1rem;
		@include ru-light;
		color: $font-grey;
		width: auto;
		a {
			color: $font-grey;
		}
	}
	.footer-menu {
		margin-left: 20px;
		width: 40%;
		display: inline-block;
		.voice {
			@include ru-light;
			font-size: .8rem;
			text-transform: uppercase;
			margin: 0 10px;
			line-height: 1rem;
			display: block;
			float: left; // width: 33.3%;
			width: 20%;
			text-align: center;
			margin: 0;
			a {
				color: $font-grey;
			}
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.copy {
		float: right;
		vertical-align: baseline;
		li {
			padding: 5px 0;
			margin-bottom: 0;
			line-height: 1.5;
		}
	}
	.social-list {
		ul {
			vertical-align: middle;
			padding-left: 0;
			li {
				list-style: none;
				justify-content: center;
				align-items: center;
			}
		}
		img {
			vertical-align: middle;
			margin-left: 5px;
		}
	}
}

.footer-wrapper-absolute {
	footer {
		z-index: 1000;
		position: absolute;
		bottom: 0;
		width: calc(100% - 20vw);
	}
}

.section-cosa-dicono {
	position: relative;
	width: 100%;
	padding: 80px 0;
	.wrapper-video {
		width: 60vw;
		margin: 50px auto;
	}
	h3 {
		color: $dark-blue;
		text-align: center;
		font-size: 3.125rem;
		@include ru-light;
	}
	.play-btn {
		left: calc(50% - 46px);
		cursor: pointer;
	}
}

.essentially-wrapper {
	.wrapper-btn {
		text-align: center;
		.btn-primary {
			margin: 30px auto;
		}
	}
	background-color: rgba(#dce5ea,
	0.10);
	padding: 5.625rem 0 20px 0;
	.title {
		@include ru-light;
		text-align: center;
		font-size: 50px;
		color: $dark-blue;
	}
	.wrapper-container {
		width: 80%;
		margin: 0 auto;
	}
	.wrapper-filter {
		// background-color:red;
		width: 70%;
		margin: 0 auto;
		text-align: center;
		text-transform: uppercase;
		@include ru-medium;
		font-size: 12px;
		color: $font-grey;
		.filter-voice {
			display: inline-block;
			margin: 50px 25px;
			cursor: pointer;
			position: relative;
			&:hover {
				&:after {
					position: absolute;
					content: "";
					width: 100%;
					height: 4px;
					background-color: $pink;
					bottom: -5px;
					left: 0;
				}
			}
			&.active {
				position: relative;
				&:after {
					position: absolute;
					content: "";
					width: 100%;
					height: 4px;
					background-color: $pink;
					bottom: -5px;
					left: 0;
				}
			}
		}
	}
}

.wrapper-post-box {
	clear: both;
	.single-post {
		width: 31%;
		display: inline-block;
		vertical-align: top;
		margin-bottom: 20px;
		position: relative;
		a {
			color: $font-grey;
		}
		&:hover {
			.link-post {
				text-decoration: underline;
			}
		}
		.wrapper-image-over {
			position: relative;
			line-height: 0;
			.overlaybox {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: #dce5ea;
				opacity: 0;
				-webkit-transition: all 0.5s ease-in-out;
				-moz-transition: all 0.5s ease-in-out;
				-ms-transition: all 0.5s ease-in-out;
				-o-transition: all 0.5s ease-in-out;
				transition: all 0.5s ease-in-out;
			}
		}
		&:hover {
			.wrapper-text-post {
				-webkit-transform: translateY(-20px);
				-moz-transform: translateY(-20px);
				-ms-transform: translateY(-20px);
				-o-transform: translateY(-20px);
				transform: translateY(-20px);
				.link-post {
					opacity: 1;
					text-decoration: underline;
				}
			}
			.wrapper-image-over {
				line-height: 0;
				.overlaybox {
					opacity: 0.58;
				}
			}
		}
		.social-icon {
			border-radius: 50%;
			background-color: #dce5ea;
			text-align: center;
			position: absolute;
			bottom: 20px;
			left: 20px;
			width: 40px;
			height: 40px;
			line-height: 55px;
			z-index: 100;
			img {
				height: 25px;
				width: auto;
			}
		}
		img {
			width: 100%;
			max-width: 100%;
		}
		&:nth-child(3n+1) {
			margin-left: 0;
			margin-right: 1%;
		}
		&:nth-child(3n+2) {
			margin-left: 1%;
			margin-right: 1%;
		}
		&:nth-child(3n+3) {
			margin-left: 1%;
			margin-right: 0;
		}
		.type-post {
			text-transform: uppercase;
			font-size: 15px;
			@include ru-light;
			display: inline;
		}
		.reading-time {
			font-size: 15px;
			@include ru-light;
			text-align: right;
			display: inline;
		}
		.box-post {
			background-color: $pink-light;
			padding: 20px 20px 0;
			margin-top: -5px;
		}
		.wrapper-text-post {
			padding: 20px 0;
			margin-top: 1.875rem;
			line-height: 1.7rem;
			-webkit-transition: all 0.5s ease-in-out;
			-moz-transition: all 0.5s ease-in-out;
			-ms-transition: all 0.5s ease-in-out;
			-o-transition: all 0.5s ease-in-out;
			transition: all 0.5s ease-in-out;
			height: 163px;
			overflow: hidden;
			p {
				font-size: 0.9375rem;
				margin-bottom: 20px;
			}
			.title-post {
				font-size: 1.875rem;
				@include ru-light;
				margin-bottom: 15px;
			}
			.wrapper-info-post {
				// margin-bottom: 40px;
				.wrapper-text {
					margin-bottom: 20px;
				}
			}
			.link-post {
				color: $font-grey;
				font-size: 12px;
				@include ru-bold;
				margin: 20px 0;
				text-decoration: underline;
				opacity: 0;
				-webkit-transition: all 0.5s ease-in-out;
				-moz-transition: all 0.5s ease-in-out;
				-ms-transition: all 0.5s ease-in-out;
				-o-transition: all 0.5s ease-in-out;
				transition: all 0.5s ease-in-out;
			}
		}
	}
	.link-post {
		text-decoration: underline;
	}
}

//ABOUT US 
.about-us-page {
	.header-img-wrapper {
		position: relative;
		height: 65vh;
		width: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		.header-intro {
			text-align: center; // margin-left: 9.6875rem;
			.subtitle {
				margin-left: 0;
			}
			&.v-center {
				top: calc(50% + 2.5rem);
				width: 60%;
				margin: 0 auto;
				left: 20%;
			}
		}
	}
	.section-slider-about-us {
		overflow: auto;
		padding: 4.6875rem 0;
		position: relative; // width: 95vw;
		width: calc(100% - 8.75rem);
		margin: 0 auto;
		.wrapper-slider {
			// height: 450px;
			height: 29.5rem;
			width: 50%;
			margin: 0 auto;
			padding: 0 0 0 0;
			display: block;
			float: left;
			.slider-about {
				width: 100%;
				height: 100%;
			}
			.owl-prev {
				text-align: center;
				left: -23px;
				top: calc(50% - 23px);
				background-color: #dce5ea !important;
				opacity: 0.8;
				border-radius: 50%;
				width: 46px !important;
				height: 46px !important;
				padding: 0 !important;
				background-size: contain !important;
				background-position: 40% center !important;
				background-image: url("/assets/a-g-prev.png") !important;
				background-size: 13px !important;
				&:hover {
					opacity: 1;
				}
			}
			.owl-next {
				text-align: center;
				right: -23px;
				top: calc(50% - 23px);
				background-color: #dce5ea !important;
				opacity: 0.8;
				border-radius: 50%;
				width: 46px !important;
				height: 46px !important;
				padding: 0 !important;
				background-size: contain !important;
				background-position: 60% center !important;
				background-image: url("/assets/a-g-next.png") !important;
				background-size: 13px !important;
				&:hover {
					opacity: 1;
				}
			}
		}
		.slider-about {
			.item {
				width: 100%;
				height: 100%;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
		.wrapper-text {
			display: block;
			float: left;
			margin-left: 1.25rem;
			&:after {
				content: "";
				display: table;
				clear: both;
			}
			.wrapper-content {
				// padding: 0 4rem 0 0;
				padding: 0 0 0 2.5rem;
				p {
					margin: 0;
				}
				.title {
					font-size: 6.25rem;
					margin-bottom: 2.5rem;
				}
			}
		}
		.owl-carousel.owl-drag .owl-item {
			height: 100%;
		}
		.owl-carousel .owl-stage-outer {
			height: 100%;
		}
		.no-js .owl-carousel,
		.owl-carousel.owl-loaded {
			height: 100%;
			margin: 0 auto;
			width: calc(100% - 35px);
		}
		.owl-carousel .owl-stage {
			height: 100%;
		}
	}
}

.sustainability-section {
	padding: 4.6875rem 0;
	background-color: #dce5ea;
	.container {
		width: calc(100% - 8.75rem);
		margin: 0 auto;
		&:after {
			content: "";
			display: table;
			clear: both;
		}
		.wrapper-image-video {
			width: 60%;
			margin-left: 4%;
			float: left;
			position: relative;
			img {
				width: 100%;
			}
			.icon-play {
				position: absolute;
				top: calc(50% - 46px);
				left: calc(50% - 46px);
				cursor: pointer;
			}
		}
		.wrapper-content {
			width: 35%;
			margin-right: 1%;
			float: left;
			.title {
				// font-size: 6.25rem;
				font-size: 4.2vw;
				text-align: justify;
				// line-height: 6rem;
				text-transform: uppercase;
				color: $light-cyan;
				opacity: 1;
				// word-wrap: break-word;
				width: 32rem;
				label {
					color: $font-blue;
					bottom: 10px;
				}
			}
		}
	}
}

.section-food-wrapper {
	position: relative;
	margin-top: 4.6875rem;
	padding: 0 0 4.6875rem;
	background-color: #FFF;
	.image-wrapper {
		display: inline-block;
		width: calc(50vw - 4.375rem); // padding-left:4.6875rem;
		padding-left: 4.375rem;
		margin-right: calc(10vw - 5.6875rem);
		img {
			width: 100%;
			max-width: 100%;
		}
	}
	.text-container {
		display: inline-block;
		width: calc(41vw - 4.6875rem);
		vertical-align: top;
		.title {
			font-size: 6.25rem;
		} // .paragraph {
		// 	padding-left: 60px;
		// }
	}
}

.container-box {
	position: relative;
	overflow: auto; // width: 100vw;
	width: 100%;
	.color-overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 100;
		background-color: rgba($dark-blue, 0.5);
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-ms-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
	}
	.single-box {
		&:hover {
			.color-overlay {
				background-color: rgba($dark-blue, 0);
			}
			.single-section {
				-webkit-filter: grayscale(0%);
				/* Safari 6.0 - 9.0 */
				filter: grayscale(0%);
			}
		}
		.label {
			color: #FFF;
			font-size: 3.125rem;
			text-align: center;
			text-transform: uppercase;
			top: 50%;
			position: absolute;
			transform: translateY(-50%);
			width: 100%;
			padding: 0;
			margin: 0;
			@include ru-light;
		}
	}
	.single-section {
		// min-height: 330px;
		min-height: 16.5rem;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		-webkit-filter: grayscale(100%);
		/* Safari 6.0 - 9.0 */
		filter: grayscale(100%);
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-ms-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		&:hover {
			-webkit-filter: grayscale(0%);
			/* Safari 6.0 - 9.0 */
			filter: grayscale(0%);
		}
	}
}

.column-3 {
	width: 33.3%;
	display: block;
	position: relative;
	padding: 0;
	margin: 0;
	font-size: 0;
	float: left;
}

.owl-theme .owl-nav [class*=owl-] {
	background-color: transparent;
	background: transparent;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	width: 34px;
	height: 19px;
	&:hover {
		background-color: transparent;
		background: transparent;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		width: 34px;
		height: 19px;
	}
}

.owl-carousel {
	position: relative;
	.owl-prev {
		background-image: url('/assets/arrow_prev.png') !important;
		background-color: transparent;
		background: transparent;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		width: 34px;
		height: 19px;
		position: absolute;
		left: 15px;
		top: calc(50% - 20px);
	}
	.owl-next {
		background-image: url('/assets/arrow_next.png') !important;
		background-color: transparent;
		background: transparent;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		width: 34px;
		height: 19px;
		position: absolute;
		right: 15px;
		top: calc(50% - 20px);
	}
}

.intro-page-wrapper {
	h2 {
		margin-left: 0;
	}
	text-align: center;
	padding:13.5rem 0 4.0625rem;
	width: 95%;
	max-width: 1024px;
	margin: auto;
	.subtitle {
		// padding: 0 25vw;
		// width: 95%;
		// max-width: 1024px;
		// margin-left: 0;
		// margin-left: auto;
		// margin-right: auto;
		margin-top: 5.3125rem;
	}
}

//FE FOR
.fe-for-page {
	.intro-page-wrapper {
		.subtitle {
			margin: 2.5rem 0;
		}
	}
	.section-slide {
		position: relative;
		width: 100%; // height: 80vh; 
		height: 75vh; // background-color: rgba($dark-blue, 0.5);
		.bottiglia-wrapper {
			// text-align: center;
			// position: absolute;
			// width: 22vw;
			// height: 90vh;
			// top: -8vh;
			// z-index: 1000;
			// left: calc(50% - 11vw);
			// background-size: contain;
			// background-repeat: no-repeat;
			// background-position: center;
			text-align: center;
			position: absolute;
			width: 18vw;
			height: 82vh;
			top: -17px;
			z-index: 1000;
			left: calc(50% - 8vw);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
	.block-slide-wrapper {
		position: relative; // overflow: auto;
		width: 100%;
		height: 100%; // overflow: auto;
		overflow: hidden;
		.single-block-slide {
			// width: 50vw;
			width: 50%;
			height: 100%;
			position: relative;
			float: left;
			-webkit-transition: all 0.5s ease-in-out;
			-moz-transition: all 0.5s ease-in-out;
			-ms-transition: all 0.5s ease-in-out;
			-o-transition: all 0.5s ease-in-out;
			transition: all 0.5s ease-in-out;
			overflow: hidden;
			.wrapper-content {
				position: relative; // width: 100%;
				height: 100%;
				z-index: 500;
			}
			.wrapper-btn {
				position: absolute; // bottom: -6.25rem;
				width: 100%;
				height: 100%;
				display: block;
				cursor: pointer;
				z-index: 1010;
			}
			.wrapper-arrow-slide {
				position: absolute;
				bottom: -6.25rem;
			}
			&.left {
				&.to-close {
					.slide {
						right: unset;
						&.close-a {
							&:before {
								position: absolute;
								width: 1.9375rem;
								height: 1.3125rem;
								background-image: url('/assets/freccia_1.png');
								content: "";
								left: -40px;
								top: -3px;
								background-size: contain;
								background-position: center;
								background-repeat: no-repeat;
							}
						}
					}
				}
				&.to-open {
					.slide {
						&.close-a {
							font-size: 0;
							line-height: 0.75rem;
							&::after {
								position: absolute;
								width: 1.9375rem;
								height: 1.3125rem;
								background-image: url('/assets/freccia.png');
								content: "";
								left: 0;
								top: -3px;
								background-size: contain;
								background-position: center;
								background-repeat: no-repeat;
							}
						}
					}
				}
				.slide {
					position: absolute;
					bottom: 50px;
					right: 4px;
					z-index: 500; // right: 20%;
					text-transform: uppercase;
					color: #FFF;
					@include ru-medium;
					font-size: 0.75rem;
					cursor: pointer;
					-webkit-transition: all 0.2s ease-in-out;
					-moz-transition: all 0.2s ease-in-out;
					-ms-transition: all 0.2s ease-in-out;
					-o-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
					&.open-a {
						&:after {
							position: absolute;
							width: 1.9375rem;
							height: 1.3125rem;
							background-image: url('/assets/freccia.png');
							content: "";
							right: -40px;
							top: -3px;
							background-size: contain;
							background-position: center;
							background-repeat: no-repeat;
						}
					}
				}
				.wrapper-text-hide {
					position: absolute;
					top: 50%;
					left: 5vw;
					z-index: 500;
					width: 35vw;
					transform: translateY(-50%);
					opacity: 0;
					-webkit-transition: all 0.5s ease-in-out;
					-moz-transition: all 0.5s ease-in-out;
					-ms-transition: all 0.5s ease-in-out;
					-o-transition: all 0.5s ease-in-out;
					transition: all 0.5s ease-in-out;
					.title {
						@include ru-medium;
						font-size: 1.875rem; // padding: 20px 40px;
						font-size: 1.875rem;
						color: $font-blue;
						padding: 1.25rem 0 1.25rem 2.5rem;
					}
					ul {
						@include ru-light;
						// font-size: 1.0715rem;
						padding-left: 2rem;
						list-style-type: disc;
						line-height: 25px;
						li {
							list-style-type: disc;
							margin: 0.5rem 0;
						}
					}
				}
				.label-slide {
					position: absolute;
					z-index: 500;
					bottom: 12%;
					// right: 18%;
					right: 14%;
					text-align:center;
					-webkit-transition: all 0.5s ease-in-out;
					-moz-transition: all 0.5s ease-in-out;
					-ms-transition: all 0.5s ease-in-out;
					-o-transition: all 0.5s ease-in-out;
					transition: all 0.5s ease-in-out;
				}
				.overlay {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: $pink; //background-color: $light-cyan;
					z-index: 100;
				}
			}
			&.right {
				&.to-close {
					.slide {
						left: 0;
						&:after {
							position: absolute;
							width: 1.9375rem;
							height: 1.3125rem;
							background-image: url('/assets/freccia.png');
							content: "";
							right: -40px;
							top: -3px;
							background-size: contain;
							background-position: center;
							background-repeat: no-repeat;
						}
					}
				}
				&.to-open {
					.slide {
						&.close-a {
							font-size: 0;
							line-height: 0.75rem;
							&::before {
								position: absolute;
								width: 1.9375rem;
								height: 1.3125rem;
								background-image: url('/assets/freccia_1.png');
								content: "";
								left: -40px;
								top: -3px;
								background-size: contain;
								background-position: center;
								background-repeat: no-repeat;
							}
						}
					}
				}
				.slide {
					position: absolute;
					bottom: 50px;
					z-index: 500;
					left: 40px;
					text-transform: uppercase;
					color: #FFF;
					@include ru-medium;
					font-size: 0.75rem;
					cursor: pointer;
					-webkit-transition: all 0.2s ease-in-out;
					-moz-transition: all 0.2s ease-in-out;
					-ms-transition: all 0.2s ease-in-out;
					-o-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
					&.open-a {
						&:before {
							position: absolute;
							width: 1.9375rem;
							height: 1.3125rem;
							background-image: url('/assets/freccia_1.png');
							content: "";
							left: -40px;
							top: -3px;
							background-size: contain;
							background-position: center;
							background-repeat: no-repeat;
						}
					}
				}
				.wrapper-text-hide {
					position: absolute;
					top: 50%;
					right: 5vw;
					z-index: 500;
					width: 35vw;
					transform: translateY(-50%);
					opacity: 0;
					-webkit-transition: all 0.5s ease-in-out;
					-moz-transition: all 0.5s ease-in-out;
					-ms-transition: all 0.5s ease-in-out;
					-o-transition: all 0.5s ease-in-out;
					transition: all 0.5s ease-in-out;
					.title {
						@include ru-medium;
						font-size: 1.875rem; // padding: 20px 40px;
						color: $font-blue;
						padding: 1.25rem 0 1.25rem 2.5rem;
					}
				}
				.label-slide {
					position: absolute;
					z-index: 500;
					bottom: 12%;
					left: 18%;
					text-align: center;
					-webkit-transition: all 0.5s ease-in-out;
					-moz-transition: all 0.5s ease-in-out;
					-ms-transition: all 0.5s ease-in-out;
					-o-transition: all 0.5s ease-in-out;
					transition: all 0.5s ease-in-out;
				}
				.overlay {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%; // background-color: rgba($pink, 0.5);
					background-color: #b4d9e9;
					z-index: 100;
				}
			}
			.image-slide-block {
				position: absolute;
				top: 0;
				left: 0;
				width: 100vw;
				height: 100%;
				background-size: auto;
				background-repeat: no-repeat;
				background-position: left;
				z-index: 101;
				-webkit-transition: all 0.5s ease-in-out;
				-moz-transition: all 0.5s ease-in-out;
				-ms-transition: all 0.5s ease-in-out;
				-o-transition: all 0.5s ease-in-out;
				transition: all 0.5s ease-in-out;
			}
			&.left {
				.image-slide-block {
					background-position: -69vw;
					&.open {
						background-position: 50vw;
					}
					&.close {
						background-position: -100vw;
					}
				}
				&.to-close {
					.image-slide-block {
						background-position: 50vw;
						left: -50vw;
						width: 200vw;
					}
				}
			}
			&.right {
				.image-slide-block {
					background-position: -2vw;
					&.open {
						background-position: 150%;
					}
				}
			}
		}
	}
	.wrapper-block-text {
		margin: (2.875*3rem) auto 0;
		width: calc(100% - 8.75rem); // width: 95vw;
		margin-left: auto;
		margin-right: auto;
		&:after {
			content: "";
			display: table;
			clear: both;
		}
		.title {
			font-size: 7.3125rem;
			line-height: 82px;
			// label {
			// 	bottom: 1.25rem;
			// 	line-height: 0;
			// }
		}
	}
	.row-single-block {
		// overflow: auto;
		margin: (2.875*2rem) 0;
		position: relative;
		&:after {
			content: "";
			display: table;
			clear: both;
		}
		.column {
			.paragraph {
				padding-left: 2.9375rem;
				em {
					@include ru-italic;
					color: $font-grey;
					font-weight: 300;
				}
			}
			&:nth-child(2) {
				margin-left: 2%;
				margin-right: 0;
			}
		}
		&.left {
			.wrapper-content {
				padding-left: 0;
				margin-top: 20%;
				width: 38vw;
			}
		}
		&.right {
			.wrapper-content {
				padding-right: 0; // margin-top:20%;
				width: 38vw;
			}
		}
		.wrapper-image {
			height: 460px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
	}
}

.ask-page {
	h1 {
		// color: $dark-blue;
		margin-bottom: 0;
	}
	.section-form {
		width: 100%;
		min-height: 40.625rem;
		background-image: url('/assets/bg_form.png');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat; // margin: 3.75rem 0 30px;
		margin: 3.75rem 0 0;
		.form-wrapper {
			width: 70vw;
			margin: 30px auto;
			overflow: auto;
			.paragraph {
				
				label {
					@include ru-italic;
				}
				padding-top: 0;
				font-size: 1rem;
				color:white;
				@include ru-light;
				p {
					margin-top: 0;
					margin-bottom: 20px;
					font-weight: normal;
					&.italic {
						@include ru-italic;
					}
				}
			}
		}
		.italic {
			@include ru-italic;
			margin-top: 20px;
		}
		.wrapper {
			width: 85vw;
			margin: 0 auto;
			overflow: auto;
			padding: 2.75rem 0;
			.subtitle {
				text-align: center;
				color: #FFF;
				font-size: 1.875rem;
				@include ru-light;
			}
			.title {
				text-align: center;
				color: #FFF;
				font-size: 4.75rem;
				@include ru-medium;
				margin-bottom: 3rem;
			}
		}
		.single-checkbox {
			margin: 15px 0;
			span.wpcf7-not-valid-tip {
				color: #f00;
				font-size: 1em;
				font-weight: normal;
				display: block;
				/* display: inherit; */
				width: 100%;
				float: right;
				margin-top: 20px;
				padding-left: 10px;
			}
		}
		input[type="checkbox"] {
			width: 1.25rem;
			height: 1.25rem;
			-webkit-appearance: none;
			border: 1px solid $bg-grey;
			padding: 0;
			background-color: #FFF;
			position: relative;
			margin: -5px 10px -5px 0;
			&:checked {
				&:after {
					position: absolute;
					content: "";
					width: 0.625rem;
					height: 0.625rem;
					background-color: $bg-grey;
					top: calc(50% - 0.3rem);
					left: calc(50% - 0.3rem);
					border-radius: 50%;
				}
			}
		}
		input {
			width: calc(100% - 40px);
			margin: 10px 0;
			padding: 10px 20px;
			@include ru-italic; // font-size: 0.9375rem;
			border: 0;
			box-shadow: 0 0px 0px 0px transparent;
			font-size: 1.0715rem;
			::-webkit-input-placeholder {
				/* Chrome/Opera/Safari */
				@include ru-italic;
			}
			::-moz-placeholder {
				/* Firefox 19+ */
				@include ru-italic;
			}
			:-ms-input-placeholder {
				/* IE 10+ */
				@include ru-italic;
			}
			:-moz-placeholder {
				/* Firefox 18- */
				@include ru-italic;
			}
		}
		textarea {
			width: calc(100% - 40px);
			padding: 10px 20px;
			@include ru-italic; // font-size: 0.9375rem;
			font-size: 1.0715rem;
			::-webkit-input-placeholder {
				/* Chrome/Opera/Safari */
				@include ru-italic;
			}
			::-moz-placeholder {
				/* Firefox 19+ */
				@include ru-italic;
			}
			:-ms-input-placeholder {
				/* IE 10+ */
				@include ru-italic;
			}
			:-moz-placeholder {
				/* Firefox 18- */
				@include ru-italic;
			}
		}
	}
	.intro-page-wrapper {
		padding: 17.5rem 0 4.0625rem;
		.subtitle {
			// padding: 0 25vw;
			// margin-left: 0;
			margin-top: 5.3125rem;
		}
	}
	.section-expert-video {
		width: 85vw;
		margin: 0 auto;
		overflow: auto;
		padding: 6.5rem 0;
		.wrapper-content {
			height: 30rem;
			width: 80vw;
			margin: 0 auto;
			position: relative;
			.title {
				font-size: 7.3125rem;
				line-height: 6.4375rem;
				font-family: 'Rubik';
				font-weight: 300;
				font-style: normal;
				color: $font-grey;
				opacity: 0.10;
				position: absolute;
				left: 36vw;
				top: 0;
			}
			.name-doc {
				font-size: 1.875rem;
				font-family: 'Rubik';
				font-weight: 300;
				font-style: normal;
				color: #5b5d64;
			}
			.role {
				font-size: 1.125rem;
				font-family: 'Rubik';
				font-weight: 500;
				font-style: normal;
				color: #5b5d64;
				margin-bottom: 34px;
				text-transform: uppercase;
			}
			.text-content {
				position: absolute;
				bottom: 5.7rem;
				left: 43vw;
			}
			.wrapper-img {
				position: absolute;
				width: 45vw;
				height: 53vh;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}
}

.wpcf7-mail-sent-ok {
	// border: 2px solid #398f14;
	text-align: center;
	padding: 1rem 0;
	width: 50%;
	margin: 1rem auto !important;
}

.accordion-wrapper {
	// width: 85vw;
	width: 95%;
	max-width: 1024px;
	margin: 0 auto;
}

.single-faq {
	// width: 95%;
	// max-width: 1024px;
	margin: 10px auto;
	.question {
		font-size: 1.25rem;
		@include ru-regular;
		background-color: rgba($light-cyan, 0.2);
		display: block;
		padding: 30px;
		padding-right: 60px;
		color: $font-grey;
		position: relative;
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-ms-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		&:after {
			position: absolute;
			content: "";
			width: 20px;
			height: 20px;
			background-image: url('/assets/plus.png');
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			right: 30px;
		}
		&.bg-dark {
			background-color: rgba($dark-blue, 0.8);
			color: white;
			&:after {
				background-image: url('/assets/meno.png');
			}
		}
	}
	.inner {
		padding-left: 1em;
		overflow: hidden;
		display: none;
		&.answer {
			background-color: rgba($light-cyan, 0.5);
			padding: 60px;
			line-height: 25px;
			// font-size: 1.0715rem;
			font-size: 1.25rem;
			color: $font-blue;
		}
	}
}

.section-video {
	width: 85vw;
	margin: 0 auto;
	overflow: auto;
	.single-video {
		width: 32%;
		height: 14.625rem;
		margin-top: 3rem;
		margin-bottom: 3rem;
		float: left;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		position: relative;
		&:nth-child(3n+1) {
			margin-left: 0;
			margin-right: 1%;
		}
		&:nth-child(3n+2) {
			margin-left: 1%;
			margin-right: 1%;
		}
		&:nth-child(3n+3) {
			margin-left: 1%;
			margin-right: 0;
		}
	}
	.video_title {
		position: absolute;
		z-index: 1;
		color: #FFF;
		left: 20px;
		bottom: 20px;
		@include ru-light;
		font-size: 1.875rem;
	}
	.video_duration {
		position: absolute;
		z-index: 1;
		color: #FFF;
		right: 20px;
		bottom: 20px;
		@include ru-light;
		font-size: 1.875rem;
	}
	.video_number {
		font-size: 5.3125rem;
		color: $font-grey;
		opacity: 0.18;
		position: absolute;
		top: -3.5rem;
	}
	.icon-play {
		text-align: center;
		top: calc(50% - 46px);
		position: absolute;
		left: calc(50% - 46px);
		cursor: pointer;
	}
}

.wrapper-btn {
	text-align: center;
	.btn-primary {
		margin: 30px auto;
	}
}

.about-us-page-details {
	.header-img-wrapper {
		position: relative;
		height: 65vh;
		width: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		.header-intro {
			margin-left: 9.6875rem;
			h1 {
				color: #ffffff;
			}
		}
	}
	@include text-label;
	.section-block-text {
		width: calc(100% - 8.75rem);
		margin: 20px auto;
		.column {
			margin-left: 0;
			margin-right: 0;
			width: 50%;
		}
		.text-wrapper {
			// margin-left: 3.125rem; // position: relative;
			&.v-align {
				margin-top: 3rem;
			}
			.paragraph {
				padding: 1.25rem 2.5rem 1.25rem 2.5rem;
			}
		}
	}
	.single-row-block {
		&:nth-child(1) {
			margin-top: 4rem;
		}
		&.right {
			.text-wrapper {
				padding-left: 3.375rem;
			}
		}
		&.video-block {
			.text-wrapper {
				padding-left: 3.375rem;
			}
			.title {
				font-size: 4.2vw;
			}
			label {
				padding-right: 2.5rem;
			}
			.paragraph {
				padding-left: 2.5rem;
				padding-right: 2.5rem;
			}
		}
		&::after {
			content: "";
			display: table;
			clear: both;
		}
		.column {
			min-height: 28.125rem;
			&::after {
				@include clearfix;
			}
			&.video-text {
				width: 38%;
			}
			&.video-img {
				width: 58%;
				position: relative;
				.icon-play {
					position: absolute;
					top: calc(50% - 46px);
					left: calc(50% - 46px);
				}
			}
		}
		position: relative; // overflow: auto;
		// margin: 2.187rem auto 2.1875*2rem;
		margin: 6rem auto;
		.image-wrapper {
			height: 28.75rem;
			@include bg-cover;
		}
	}
	.section-details-box-link {
		margin-top: 2rem;
		.column {
			width: 50%;
			position: relative;
			margin: 0;
		}
	}
}

.back-to {
	&.s-size {
		font-size: 0.8625rem;
		&:before {
			width: 22px;
			height: 11px;
			padding: 0 0 0 25px;
			top: calc(50% - 5.5px);
		}
	}
	text-transform: uppercase;
	color: #b0b7bc;
	font-size: 1rem;
	font-family: 'Rubik';
	font-weight: 500;
	font-style: normal;
	margin: 2rem 4.375rem 0;
	position: relative;
	padding: 0 0 0 45px;
	display: -webkit-box;
	width: fit-content;
	&:before {
		position: absolute;
		width: 30px;
		height: 19px;
		content: "";
		background-image: url("/assets/back.png");
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		left: 0;
		top: calc(50% - 9.5px);
	}
}

.cookie-page {
	h1 {
		color: $dark-blue;
	}
	// .intro-page-wrapper {
	// 	.subtitle {
	// 		padding: 0 14.6875rem;
	// 	}
	// }
	.content-page {
		// width: calc(100% - 8.75rem);
		width: 95%;
		max-width: 1024px;
		margin: 0 auto;
		// .single-faq .question {
		// 	padding-left: 2.9375rem;
		// }
		.block-text-and-title {
			margin: 3.9375rem auto;
			// width: calc(100% - 7.875rem);
			// width: 95%;
			// max-width: 1024px;
			.title {
				@include ru-regular;
				color: #5b5d64;
				font-size: 1.875rem;
			}
			.text {
				@include ru-light;
				// font-size: 1.0715rem;
				font-size: 1.25rem;
				// line-height: 1.5625rem;
				line-height: 1.5;
				margin-bottom: 1rem;
				// line-height: 1;
				p {
					margin: 1.1875rem auto;
					// line-height: 1.5625rem;
					// font-size: 1.0715rem;
					a {
						@include ru-medium;
						text-decoration: underline;
						color: #5b5d64;
					}
				}
			}
		}
	}
}

.page-post-single {
	.header-img-wrapper {
		height: 35rem;
		width: 100%;
		@include bg-cover;
		position: relative;
		text-align: center;
		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #5b5d64;
			opacity: 0.6;
		}
		.header-intro {
			width: 100%;
			text-align: center;
			h1 {
				text-align: center;
				color: #FFF;
				@include ru-light;
				padding: 0 25%;
				margin-top: 1.8rem;
				position: relative;
				&:after {
					position: absolute;
					content: "";
					width: 10%;
					height: 4px;
					background-color: #51aedf;
					bottom: -20%;
					left: 45%;
				}
			}
			.category-post {
				color: #51aedf;
				text-transform: uppercase;
				@include ru-medium;
			}
		}
	}
	.wrapper-content {
		width: 100%;
		position: relative;
		overflow: auto;
		margin: 0 auto 2rem;
		p {
			max-width: 100%;
		}
		.category {
			text-transform: uppercase;
			position: relative;
			margin-bottom: 10px;
			&:after {
				position: absolute;
				content: "";
				width: 48px;
				bottom: -5px;
				left: 0;
				height: 2px;
				background-color: #51aedf;
			}
		}
		.info-post-author {
			font-size: 0.75rem;
			@include ru-regular;
		}
		.share-list {
			width: 90%;
			margin: 0 auto;
			text-align: center;
			li {
				display: inline-block;
				margin: 0 0.9375rem;
				pointer-events: auto;
			}
		}
		.info-post {
			width: calc(20% - 4.375rem);
			margin-left: 4.375rem;
			margin-right: 1%;
			float: left;
		}
		.share-wrapper {
			width: 20%;
			margin-left: 1%;
			margin-right: 0;
			float: left;
		}
		.content {
			width: 58%;
			margin: 0;
			float: left;
			font-size: 1.2715rem;
			em {
				color: #51aedf;
				@include ru-italic;
				font-weight: 500;
				width: 90%;
				margin-left: auto;
				margin-right: auto;
				display: block;
			}
			a {
				color: #51aedf;
				text-decoration: underline;
			}
			img {
				padding: 2rem 10%;
				width: 80%;
			}
		}
	}
}

.contact-page {
	height: calc(100vh - 70px);
	.container {
		width: calc(100% - 8.75rem);
		margin: 0 auto;
		height: 100%;
		.wrapper-content {
			width: calc(100% - 5%);
			margin: 0 auto;
			h1 {
				// color: #51aedf;
				color: #009cdd;
				margin-bottom: 3.75rem;
				margin-left: 35px;
			}
			.image-wrapper {
				text-align: center;
			}
			.wrapper-information {
				margin-left: 70px;
				h2,
				h3 {
					@include ru-light;
					font-size: 1.875rem;
				}
				p {
					font-size: 1.25rem;
					line-height: 1.875rem;
					a {
						color: #5b5d64;
						@include ru-medium;
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.policy-page {
	.intro-page-wrapper {
		margin-bottom: 6rem;
	}
	h1 {
		color: $dark-blue;
		text-align: center; // margin-top: 6.25rem;
	}
	.content-page {
		// width: calc(100% - 14.125rem);
		width: 95%;
		max-width: 1024px;
		margin: 0 auto 4.30rem;
		height: 100%;
	}
	.title {
		font-size: 1.875rem;
		margin: 0 0 50px;
	}
	.block-text-and-title {
		margin: 3rem 0 0;
		p {
			// line-height: 1.5625rem;
			line-height: 1.5;
			a {
				color: #5b5d64;
				@include ru-medium;
				text-decoration: underline;
			}
		}
		.title {
			@include ru-regular;
		}
		h3 {
			@include ru-bold;
			font-size: 1.25rem;
			margin-left: 0;
			position: relative;
			margin-bottom: 2rem;
			margin-top: 2rem;
			&:after {
				position: absolute;
				content: "";
				left: 0;
				bottom: -1rem;
				width: 50px;
				height: 4px;
				background-color: $dark-blue;
			}
		}
	}
	.two-column-wrapper {
		columns: 2;
		column-gap: 9.375rem;
		.text-column {
			height: 8.9375rem;
			margin-bottom: 2rem;
			.subtitle {
				@include ru-bold;
				font-size: 1.18rem;
				margin-left: 0;
				position: relative;
				margin-bottom: 2rem;
				margin-top: 0;
				&:after {
					position: absolute;
					content: "";
					left: 0;
					bottom: -1rem;
					width: 50px;
					height: 4px;
					background-color: $dark-blue;
				}
			}
			p {
				line-height: 1.5625rem;
				// font-size: 1.0715rem;
			}
		}
	}
}

.page-template-essentially {
	.essentially-wrapper {
		padding: 0;
		.subtitle {
			padding: 0 25vw;
			margin-left: 0;
		}
	}
}

#cookiebar {
	width: 100%;
	position: fixed;
	z-index: 1000;
	bottom: 0;
	left: 0;
	background-color: rgba(180, 218, 232, 0.8);
	padding: 1rem 2rem;
	font-size: 12px;
	.cookie-bar-text {
		width: calc(50% - 10rem);
		display: inline-block;
		font-size: 14px;
		padding: 0 5rem;
		@include ru-medium;
	}
	.more-info {
		width: 20%;
		display: inline-block;
		text-align: center;
		@include ru-bold;
		font-size: 12px;
		text-decoration: underline;
		color: $font-grey;
		a {
			color: $font-grey;
		}
	}
	.btn-primary {
		display: inline-block;
		background-color: $dark-blue;
		text-align: center;
		&:hover {
			background-color: $font-grey;
		}
	}
}

.single-es_wall_post {
	.back-to {
		margin: 4.375rem;
		pointer-events: all;
	}
}

.page-template-essential_is {
	.parte-scroll-misto {
		height: 0;
	}
	.section-boario {
		.wrapper-content {
			max-width: 600px;
			top: calc(50% + 20px);
		}
		.wrapper-logo {
			text-align: center;
			.logo {
				max-width: 100%;
			}
		}
		.wrapper-img {
			height: 60vh;
			width: 40vw;
			background-repeat: no-repeat;
			background-position: center 0; // margin: 30px auto 0;
			margin: 0 auto 0;
			background-size: contain;
			position: relative;
			&:before {
				opacity: 0;
				content: "1L";
				display: block;
				width: 18vh;
				height: 18vh;
				background: $font-blue;
				color: white;
				font-size: 5rem;
				@include ru-bold;
				border-radius: 100%;
				line-height: 18vh;
				position: absolute;
				top: 8vh;
				left: 8vh;
			}
			&.handy {
				&:before {
					content: "400 ML";
					width: 15vh;
					height: 15vh;
					font-size: 2rem;
					line-height: 15vh;
					top: 20vh;
					left: 10vh;
				}
			}
			img {
				width: auto;
				max-width: 100%;
			}
		}
		&.fade-up {
			&.move-up {
				.wrapper-img {
					&:before {
						animation: zoomIn .4s linear;
						animation-fill-mode: forwards;
						animation-delay: .5s;
					}
				}
			}
		}
	}
	.wrapper-find-store {
		z-index: 0;
	}
	footer {
		width: 100%;
		background-color: $footer-bg;
		position: absolute;
		// top: 100vh;
		bottom:0;
		z-index:1;
	}
	
}

div.wpcf7-mail-sent-ok {
	border: 0 !important;
	@include ru-bold;
	color: $dark-blue;
}

.fade-up,
.fade-up-1 {
	opacity: 0;
	transition: all .6s ease;
	transition-delay: .1s;
	&.move-up {
		transform: translateY(0);
		opacity: 1;
	}
}

.sup-paragraph {
	line-height: 1em;
}

.soddisfatti-page {
	.btn-primary {
		width: 28.0625rem;
	}
	a {
		&.link {
			pointer-events: all;
			color: #b0b7bc;
			font-size: 14px;
			@include ru-bold;
			position: relative;
			margin: 20px 0;
			-webkit-transition: all 0.5s ease-in-out;
			-moz-transition: all 0.5s ease-in-out;
			-ms-transition: all 0.5s ease-in-out;
			-o-transition: all 0.5s ease-in-out;
			transition: all 0.5s ease-in-out;
			&::after {
				position: absolute;
				content: "";
				width: 20px;
				height: 2px;
				background-color: $dark-blue;
				margin-left: 0;
				right: -30px;
				top: calc(50% - 1px); // background-image: url('../assets/line_link.png');
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				-ms-transition: all 0.2s ease-in-out;
				-o-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
			}
			&:hover {
				color: $dark-blue;
				&::after {
					background-color: transparent;
					position: absolute;
					content: "";
					width: 50px;
					height: 19px;
					right: -65px;
					top: calc(50% - 9.5px);
					background-image: url('../assets/arrow-link.png');
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
				}
			}
		}
	} // height: 100%;
	.container {
		width: calc(100% - 140px);
		margin-left: auto;
		margin-right: auto;
		padding-top: 20vh;
		height: 92vh; // margin-bottom: 50px;
		.left-margin {
			padding-left: 50px;
		}
		.paragraph {
			padding: 1.25rem 0 1.25rem;
		}
		.subtitle {
			padding: 0;
			margin-left: 0;
		}
		&::after {
			content: "";
			display: table;
			clear: both;
		}
		.text-container {
			width: 38%;
			/* float: left; */
			margin-left: 0;
			margin-right: 2%;
			top: 50%;
			transform: translateY(-50%);
			position: relative;
		}
		.image-wrapper {
			width: 50%;
			margin-left: 0;
			margin-right: 0;
			padding: 0;
			/* float: left; */
			position: absolute;
			// height: 100%;
			top: 18%;
			/* transform: translateY(-50%); */
			left: 45%;
			.fardello {
				// top: -10%;
				// position: absolute;
				// left: 10%;
				// width: 90%;
			}
			&::after {
				content: "";
				display: table;
				clear: both;
			}
		}
	}
}
.privacy-link-form{
	color:#FFF;
	@include ru-light;
}
@media screen and (max-width: 1680px) {
	.section-boario .wrapper-claim {
		width: 34vw;
	}
}