.gdpr-privacy-bar{
    background-color:rgba(180, 218, 232, 0.8) !important;
}
.gdpr.gdpr-privacy-bar .gdpr-wrapper .gdpr-content p{
    color:#5b5d64 !important;
    font-family: 'Rubik' !important;
}

.gdpr.gdpr-privacy-bar .gdpr-wrapper .gdpr-right button:first-of-type,
.gdpr.gdpr-privacy-bar .gdpr-preferences:before{
    color:#5b5d64 !important;
    font-family: 'Rubik' !important;
    text-shadow: none !important;
}

.gdpr.gdpr-privacy-bar .gdpr-agreement:before,
.gdpr.gdpr-privacy-bar .gdpr-agreement:after{
    display:none !important;
}
.gdpr.gdpr-privacy-bar .gdpr-agreement{
    background:#009cdd !important;
    border:0px solid transparent !important;
    box-shadow:none !important;
    padding:12px 36px 12px 36px !important;
    cursor:pointer !important;
}
.gdpr-reconsent-modal{
    display:none !important;
}