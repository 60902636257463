@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .hidden-menu{
        display:none;
    }
    
    .fe-for-page .block-slide-wrapper .single-block-slide .image-slide-block{
        background-position:0vw;
    }
    .quotes-section{
        .fade-up,
        .fade-up-1{
            opacity: 1;
        }
    }
    .statistic-box-essenziale .source{
        bottom:1vh;
    }
    .about-us-page .section-slider-about-us{
        overflow: hidden;
    }
}
